import { useGSAP } from '@gsap/react'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import Draggable from 'gsap/dist/Draggable'
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin'
import { useWindowSize } from './useWindowSize'
import { clamp } from 'utils'

export interface SliderOptions {
	refreshToken?: any

	/**
	 * The resistance of the slider when throwing. Default is 1000, higher numbers is stiffer
	 */
	throwResistance?: number
}

export const useSlider = (options?: SliderOptions): [ref: MutableRefObject<HTMLUListElement>, currentSlide: number, goToSlide: (index: number) => void] => {
	const [currentSlide, setCurrentSlide] = useState<number>(0)
	const sliderRef = useRef<HTMLUListElement>(null)
	const draggableRef = useRef<Draggable>(null)
	const snapPointsRef = useRef<number[]>([])

	const windowSize = useWindowSize()

	const goToClosestSnapPoint = (x: number) => {
		let closestSnapPointIndex = 0
		let smallestDistance = Math.abs(snapPointsRef.current[0] - x)

		for (let i = 1; i < snapPointsRef.current.length; i++) {
			const distance = Math.abs(snapPointsRef.current[i] - x)
			// We use <= to go the end if it's equal
			if (distance <= smallestDistance) {
				smallestDistance = distance
				closestSnapPointIndex = i
			}
		}

		setCurrentSlide(closestSnapPointIndex)
	}

	useGSAP(() => {
		// Must register plugins before use
		gsap.registerPlugin(Draggable, InertiaPlugin)

		if (!sliderRef.current) return

		const slider = sliderRef.current
		const children = Array.from(slider.children)

		const leftMostLeftBound = children[0].getBoundingClientRect().left
		const rightMostRightBound = children[children.length - 1].getBoundingClientRect().right
		const sliderRightBound = leftMostLeftBound - rightMostRightBound + slider.clientWidth

		snapPointsRef.current = children.map((child) => clamp(leftMostLeftBound - child.getBoundingClientRect().left, sliderRightBound, leftMostLeftBound))

		draggableRef.current = Draggable.create(slider, {
			type: 'x',
			inertia: true,
			snap: snapPointsRef.current,
			zIndexBoost: false,
			throwResistance: options?.throwResistance ?? 1000,
			bounds: {
				maxX: 0,
				minX: sliderRightBound,
			},
			onDragEnd: function () {
				/*
				`this` refers to the Draggable here.
				From the gsap documentation:
				The ending x (horizontal) position of the Draggable instance
				which is calculated as soon as the mouse/touch is released
				after a drag, meaning you can use it to
				predict precisely where it'll land after an inertia flick.
				 */
				goToClosestSnapPoint(this.endX)
			},
		})[0] // Draggable.create returns an array
	}, [windowSize.width + windowSize.height, options?.refreshToken])

	useEffect(() => {
		// Ensure we don't update the position if a throw animation is currently in progress
		if (!draggableRef.current || draggableRef.current.isThrowing) return

		// Updates the slider position when currentSlide changes
		const newPosX = snapPointsRef.current[currentSlide]
		gsap.to(sliderRef.current, { duration: 0.5, x: newPosX })
	}, [currentSlide])

	const goToSlide = (slideIndex: number) => {
		if (!draggableRef.current) return

		if (draggableRef.current.tween) {
			draggableRef.current.tween.kill()
		}

		setCurrentSlide(slideIndex)
	}

	return [sliderRef, currentSlide, goToSlide]
}
