import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { ThumbnailsProps } from 'components/ImageCarousel/ImageCarousel.types'
import styles from './Thumbnails.module.scss'
import { Button } from 'components/Phantom/Button'
import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import { ImageProps, VideoProps } from 'components/_utils/utilityTypes'
import { Img } from 'components/basic/Img'
import { Icon } from 'components/Phantom/Icon'
import cx from 'classnames'

export const Thumbnails: FC<ThumbnailsProps> = (props) => {
	const { items, currentIndex, setCurrentIndex, useDots, maxThumbnails = items.length } = props
	const [translateX, setTranslateX] = useState(0)
	const thumbnailRef = useRef<HTMLUListElement>(null)

	useEffect(() => {
		const halfMax = Math.floor(maxThumbnails / 2)
		let newTranslateX = 0

		if (currentIndex >= halfMax && items.length > maxThumbnails) {
			if (currentIndex < items.length - halfMax) {
				newTranslateX = -(currentIndex - halfMax) * 100
			} else {
				newTranslateX = -(items.length - maxThumbnails) * 100
			}
		}

		setTranslateX(newTranslateX)
	}, [currentIndex, items.length, maxThumbnails])

	if (items.length <= 1) return null

	return (
		<div
			className={cx(styles.thumbnails_container, props.className)}
			style={{ '--visible-thumbnails': maxThumbnails } as CSSProperties}
		>
			<ul
				ref={thumbnailRef}
				className={styles.thumbnails}
				data-use-dots={useDots}
				style={
					{
						'--num-columns': items.length,
						transform: `translateX(${translateX / maxThumbnails}%)`,
						transition: 'transform 0.3s ease-out',
					} as CSSProperties
				}
			>
				{items.map((item, index) => (
					<li
						key={index}
						className={styles.thumbnail}
						data-highlight={index === currentIndex}
					>
						<Button.Empty
							id={`thumbnail-${index}`}
							ariaLabel={`Go to slide ${index + 1}`}
							className={styles.thumbnail_button}
							onClick={() => setCurrentIndex(index)}
						>
							<div className={styles.thumbnail_dot} />
							<ThumbnailRendered
								{...item}
								index={index}
								active={currentIndex === index}
							/>
						</Button.Empty>
					</li>
				))}
			</ul>
		</div>
	)
}

const ThumbnailRendered: FC<StackItemProps & { index: number; active: boolean }> = (props) => {
	const { type, data, index, active } = props

	switch (type) {
		case 'image': {
			const _data = data as ImageProps
			return (
				<Img
					src={_data.src}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={_data.objectFit ?? 'cover'}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.thumbnail_video_container}>
					<Icon
						name={active ? 'PauseDark' : 'PlayDark'}
						color={'white'}
					/>
					<Img
						src={_data.poster}
						dprHeight={300}
						alt={`Thumbnail for slide ${index + 1}`}
						className={styles.thumbnail_image}
						objectFit={'cover'}
					/>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<Img
					src={_data.poster}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={'cover'}
				/>
			)
		}
	}
}
