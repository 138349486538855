import React, { FC, useState, useEffect } from 'react'
import { Video } from './Video'
import { debounce } from 'utils'
import { Img } from 'components/basic/Img'

export interface VideoXY {
	url: string
	x: number
	y: number
	breakpoint: number
	thumb?: string
}

export interface ResponsiveVideoProps {
	videos: VideoXY[]
	caption: string
	showControls?: boolean
	autoplay?: boolean
	preload?: string
	noLoop?: boolean
	// passRefUp: Function;
}

function getThumbSources(videos: VideoXY[]) {
	return videos.map((vid) => {
		return {
			src: vid.thumb || '',
			mediaQuery: `screen and (min-width: ${vid.breakpoint}px)`,
		}
	})
}

export const ResponsiveVideo: FC<ResponsiveVideoProps> = (props) => {
	const [screenSize, setScreenSize] = useState(typeof window !== 'undefined' ? window.innerWidth : -1)
	const [hasMounted, setHasMounted] = useState<boolean>(false)

	const debouncedResize = debounce(handleResize, 300)

	useEffect(() => {
		setHasMounted(true)
	}, [])

	useEffect(
		function setScreenListeners() {
			window.addEventListener('resize', debouncedResize)
			return () => window.removeEventListener('resize', debouncedResize)
		},
		[debouncedResize]
	)

	if (!props.videos.length) {
		return null
	}

	function getBreakpointVideo() {
		const sortedVideos = sortBreakpoints()
		const breakpointVideo = sortedVideos.find((video) => screenSize >= video.breakpoint)
		return breakpointVideo || sortedVideos[0]
	}

	function handleResize() {
		if (typeof window === 'undefined') {
			return
		}
		setScreenSize(window.innerWidth)
	}

	function sortBreakpoints(): VideoXY[] {
		// sorts videos in DESCENDING order based on breakpoint.
		if (props.videos) {
			const sortedDescend = props.videos.sort(function (a, b) {
				return b.breakpoint - a.breakpoint
			})
			return sortedDescend
		}
		return []
	}

	const video = getBreakpointVideo()

	if (hasMounted) {
		return (
			<Video
				key={video.toString()}
				thumb={video.thumb}
				src={video.url}
				caption={props.caption}
				showControls={props.showControls}
				preload={props.preload}
				noLoop={props.noLoop}
			/>
		)
	}
	return (
		<Img
			sources={getThumbSources(props.videos)}
			src={video.thumb || ''}
			alt=""
			height={video.y}
			objectFit={'cover'}
		/>
	)
}
