/* eslint-disable @typescript-eslint/ban-ts-comment */
import App, { AppContext } from 'next/app'
import ReactPixel from '../utils/reactPixel'
import Router from 'next/router'
import { Provider } from 'mobx-react'
import Head from 'next/head'
import React from 'react'
import Modal from 'react-modal'
import QueryString from 'query-string'
import { initializeStore, rootStore, RootStoreInitializationData } from '../stores/index'
import { trackABtests, trackClickedChat, trackFirstPageVisit, trackWebsiteVisit } from 'events/index'
import { AppMetadata } from 'components/AppMetadata'
import { getUUID } from 'utils/browser'
import { kyViewedMenopauseCampaign } from 'events/klaviyo' // used to trigger CA when email captured via modal
import { customQueryParamsToTrackInGA } from 'constants/events'
import ReactGA, { pageview, set } from 'react-ga'
import ReactGAV4 from 'react-ga4'
import { EmailCapContainerProps } from 'emailcaps/EmailCapContainer'
import { trackPageView, updateUserUTMs } from '../events'
import { config, hreflangMap, RegionShort } from 'utils/internationalization'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import { GOOGLE_ANALYTICS_ID, GOOGLE_MEASUREMENT_ID, SITE_DOMAIN } from 'constants/index'
import { CF_COUNTRY_MAP, getCookie, setCookie, VANITY_SESSION_KEY } from 'config/utils'
import 'styles/global.scss'
import 'styles/dark.css'
import 'styles/grey.css'
import 'animate.css'
import ErrorBoundary from 'components/basic/ErrorBoundary/ErrorBoundary'
import { abtests, COOKIE_FOR_IDS, COOKIE_FOR_KEYS, getCookieABTests } from 'ab_testing'
import { getNormalizedURL } from 'components/_utils/urlUtils'
import { ISupportChat } from 'components/SupportChat/SupportChat'
import { LocalizeModalProps } from 'components/LocalizeModal/LocalizeModal'
import localFont from 'next/font/local'
import { PrimaryEmailCapWaypointProps } from 'emailcaps/SecondaryEmailCapModal/PrimaryEmailCapWaypoint'
import { QuizEcapProps } from 'emailcaps/QuizEcap/QuizEcap'
import { toJS } from 'mobx'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import * as eventNames from 'timerevents/eventNames'
import { loadKlaviyoObject } from 'utils/klaviyo'
import { datadogRum } from '@datadog/browser-rum'
import * as amplitude from '@amplitude/analytics-browser'
import { AMPLITUDE_API_KEY } from 'events/amplitude'
import { datadogReportAPICall, datadogReportError, datadogReportEvent } from 'events/datadog'
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { PriceManager } from 'prices'
import { setDataLayerVariable } from 'events/ga'

// test comment
declare global {
	interface Window {
		Kustomer?: any
		Localize?: any
		pdst: (p: string) => void
		listabtests: () => void
	}
}

const commitSHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? ''

datadogRum.init({
	applicationId: 'aa1ef1da-b822-47b1-825f-4f1aa77d758a',
	clientToken: 'pub959da72febe5112786198209bb55046a',
	// `site` refers to the Datadog site parameter of your organization
	// see https://docs.datadoghq.com/getting_started/site/
	site: 'datadoghq.com',
	service: 'eight-web',
	env: process.env.NEXT_PUBLIC_IS_PROD ? 'prod' : 'dev',
	version: commitSHA.slice(0, 7),
	sessionSampleRate: 10, //10%
	sessionReplaySampleRate: 1, //1%
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
})

if (typeof window !== 'undefined') {
	ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
		debug: false,
	})
	ReactGA.plugin.require('ec')
	ReactGA.plugin.require('linker')
	// ReactGA.ga('linker:autoLink', ['rechargeapps.com', 'eightsleep.com'], false, true)

	ReactGAV4.initialize(GOOGLE_MEASUREMENT_ID, {
		gaOptions: { allowLinker: true },
	})
	// ReactGAV4.ga('linker:autoLink', ['rechargeapps.com', 'eightsleep.com'], false, true)

	// ReactPixel
	const debug =
		document.location.hostname === 'staging.eightsleep.com' ||
		document.location.hostname === 'localhost' ||
		document.location.hostname === 'dev.eightsleep.com' ||
		document.location.hostname === 'podpro2020.eightsleep.com' ||
		document.location.hostname === 'testing.eightsleep.com' ||
		process.env.NODE_ENV !== 'production'
	const REACT_PIXEL_ID = '1494562944140395'
	ReactPixel.init(REACT_PIXEL_ID, {}, { debug, autoConfig: true, disablePushState: true })
}

const EmailCapContainerLazy = dynamic<EmailCapContainerProps>(() => import('emailcaps/EmailCapContainer'), {
	ssr: false,
})

const QuizEcapLazy = dynamic<QuizEcapProps>(() => import('emailcaps/QuizEcap/QuizEcap'), {
	ssr: false,
})

const LocalizeModalLazy = dynamic<LocalizeModalProps>(() => import('components/LocalizeModal/LocalizeModal'), {
	ssr: false,
})

const PrimaryEmailCapWaypointLazy = dynamic<PrimaryEmailCapWaypointProps>(() => import('emailcaps/SecondaryEmailCapModal/PrimaryEmailCapWaypoint'), {
	ssr: false,
})

const SupportChatLazy = dynamic<ISupportChat>(() => import('components/SupportChat/SupportChat'), {
	ssr: false,
})

const NeueMontreal = localFont({ src: '../styles/fonts/NeueMontreal/PPNeueMontreal-Variable.woff2', weight: '200 800', variable: '--neue-montreal' })
// TODO enable this when we're ready to switch the font globally
//	I'm placing this flag now so that we can have access to it on main, which will allow us to create better
//	branches as we create the component system.
const USE_NEUE_MONTREAL = true
const NEUE_CLASS = USE_NEUE_MONTREAL ? NeueMontreal.className : ''

const EXIT_INTENT_TIMEOUT = 90 * 1000
let exitIntentTimer = null

const handleSpecialEcap = async () => {
	const email = localStorage.getItem('email')
	const quizEcapDone = localStorage.getItem('quizEcapDone') === 'true'
	if (email && !quizEcapDone) {
		try {
			const result = await fetch('/api/fetch-segment-member', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Cache-Control': 'no-cache',
				},
				body: JSON.stringify({ email }),
			})
			datadogReportEvent('success', 'handleSpecialEcap')
			datadogReportAPICall(result.status, 'handleSpecialEcap')

			const json = await result.json()
			if (json.success && json.result) {
				return true
			}
		} catch (e) {
			datadogReportEvent('failure', 'handleSpecialEcap', e)
		}
	}
	return false
}

export default class MyApp extends App {
	constructor(props) {
		super(props)
		this.lastPageTracked = ''
	}

	lastPageTracked = null
	showExitIntentPopUp = () => {
		const normalizedURL = getNormalizedURL(this.props.router)
		const displayPopUp =
			typeof window !== 'undefined' &&
			!rootStore.modalStore.emailCapModalVisible &&
			rootStore.modalStore.hideEmailCapModal &&
			rootStore.modalStore.showEmailCapModalShortcut &&
			localStorage.getItem('email') == null &&
			sessionStorage.getItem(eventNames.emailCapModalTriggerDone) &&
			!sessionStorage.getItem(eventNames.emailCapShortcutButtonTriggerDone) &&
			normalizedURL.startsWith('/product') &&
			!normalizedURL.includes('/sleep-elixir')
		if (displayPopUp) {
			rootStore.modalStore.toggleExitIntenEcap(true)
		}
	}

	async componentDidMount() {
		const uaPlugin = userAgentEnrichmentPlugin()
		const sessionReplayTracking = sessionReplayPlugin({
			sampleRate: 0.05,
		})
		amplitude.add(uaPlugin)
		amplitude.add(sessionReplayTracking)
		if (typeof window !== 'undefined') {
			amplitude.init(AMPLITUDE_API_KEY, undefined, {
				cookieOptions: { upgrade: true },
				defaultTracking: {
					attribution: true,
					pageViews: false,
					formInteractions: false,
					fileDownloads: false,
					sessions: true,
				},
			})
			window['toJS'] = toJS
			window.listabtests = function () {
				return abtests.map((it) => it.key)
			}

			setDataLayerVariable('currency', PriceManager.getRegionCurrency(rootStore.settingsStore.currentRegion))

			setTimeout(() => {
				if (window.location.search) {
					const urlSearchParams = new URLSearchParams(window.location.search)

					if (abtests.find((abtest) => urlSearchParams.has(abtest.key))) {
						const abKeysCookie = getCookie(COOKIE_FOR_KEYS)
						const abTestIdsCookie = getCookie(COOKIE_FOR_IDS)

						let abKeysCookieUpdated = abKeysCookie
						let abTestIdsCookieUpdated = abTestIdsCookie
						abtests.forEach((abtest) => {
							const testVariant = urlSearchParams.get(abtest.key)
							if (testVariant) {
								abKeysCookieUpdated = abKeysCookieUpdated.replace(new RegExp(`${abtest.key}_\\d+`), `${abtest.key}_${testVariant}`)
								if (!abKeysCookieUpdated.includes(`${abtest.key}_${testVariant}`)) {
									abKeysCookieUpdated += `;${abtest.key}.${testVariant}`
								}

								abTestIdsCookieUpdated = abTestIdsCookieUpdated.replace(new RegExp(`${abtest.id}.\\d+`), `${abtest.id}.${testVariant}`)
								if (!abTestIdsCookieUpdated.includes(`${abtest.id}.${testVariant}`)) {
									abTestIdsCookieUpdated += `;${abtest.id}.${testVariant}`
								}
							}
						})

						if (abKeysCookie !== abKeysCookieUpdated && abTestIdsCookie !== abTestIdsCookieUpdated) {
							document.body.style.opacity = '0.2'
							setCookie(COOKIE_FOR_KEYS, abKeysCookieUpdated, 60, !location.hostname.startsWith('localhost') ? `.${location.hostname}` : location.hostname)
							setCookie(COOKIE_FOR_IDS, abTestIdsCookieUpdated, 60, !location.hostname.startsWith('localhost') ? `.${location.hostname}` : location.hostname)
							alert('AB tests cookie updated. Confirm to reload the page and see the changes.')
							window.location.reload()
						}
					}
				}
			}, 3000)

			window['rootStore'] = rootStore

			if (window.location.href.includes('cx=1')) {
				sessionStorage.setItem('cx_agent', 'true')
			}

			window.sessionStorage.setItem('block-exit-intent', 'true')
			if (exitIntentTimer) {
				clearTimeout(exitIntentTimer)
			}
			exitIntentTimer = setTimeout(() => {
				window.sessionStorage.removeItem('block-exit-intent')
			}, EXIT_INTENT_TIMEOUT)

			const resetTimer = () => rootStore.timerStore.events.InactivityModalEvent?.resetTimer(this.showExitIntentPopUp)

			window.onload = resetTimer
			// DOM Events
			document.onmousemove = resetTimer
			document.onkeydown = resetTimer
			document.onmousedown = resetTimer
		}

		rootStore.cartStoreNew.initCart()
		rootStore.priceStore.syncPrices()

		const automated_test = window.location.search.includes('automated=true') || sessionStorage.getItem('automated') === 'true'
		if (automated_test || window.location.search.includes('kustomer_test=true')) {
			sessionStorage.setItem('automated', 'true')
		}

		// const location = typeof window !== 'undefined' && window.location.hostname.includes('eightsleep.com') ? await getCurrentLocation() : { countryCode: 'US' }
		// rootStore.settingsStore.setRegionCautious(location && location.countryCode ? location.countryCode.toLowerCase() : 'US')

		Modal.setAppElement('#__next')

		if (window.location.search.toLowerCase().includes('campaign') && window.location.search.toLowerCase().includes('menopause')) {
			kyViewedMenopauseCampaign()
		}

		if (!automated_test) {
			// Kustomer
			this.initializeKustomer()
			setTimeout(() => {
				trackWebsiteVisit()
				// Land on page PageView
				trackPageView()
				trackABtests()
				this.trackPageView()
			}, 3000)

			// setTimeout(() => {
			// 	const scrollEventHandler = () => {
			// 		window.requestAnimationFrame(function () {
			// 			rootStore.modalStore.modalEvent.run(rootStore.timerStore.pagesVisited)
			// 			document.removeEventListener('scroll', scrollEventHandler)
			// 		})
			// 	}
			// 	document.addEventListener('scroll', scrollEventHandler)
			// }, 2000)

			this.trackAdBlock()
		}

		// Handle route change
		Router.events.on('routeChangeComplete', this.handleRouteChange)
		trackFirstPageVisit()

		// parse utms
		this.parseUTMinfo()

		function handleShippingABQuery() {
			const query = window.location.search
			if (!query.length) {
				return
			}
			const params = new URLSearchParams(query)
			const fromCX = params.has('CX') || params.has('cx')
			const fromGoogleSearch = query.includes('utm_source=google&utm_medium=shopping')
			if (fromCX || fromGoogleSearch) {
				localStorage.setItem('excludeShippingAB', '1')
			}
		}
		handleShippingABQuery()

		if (typeof window !== 'undefined') {
			if (
				sessionStorage.getItem('automated') !== 'true' &&
				localStorage.getItem('confirmedRegion') == null &&
				rootStore.settingsStore.currentRegion !== '' &&
				rootStore.settingsStore.currentRegion !== 'us'
			) {
				rootStore.settingsStore.setLocalizeReady()
				rootStore.modalStore.displayLocalizeModal()
			}
			handleSpecialEcap().then((flag) => {
				rootStore.modalStore.toggleQuizEcap(flag)
			})
		}
	}

	exitIntentEvent = () => {
		if (window.sessionStorage.getItem('block-exit-intent') === 'true') {
			return
		}

		const exitIntentEventBlocked = sessionStorage.getItem('pause_exit_intent') === '1'
		rootStore.timerStore.events.InactivityModalEvent?.resetTimer(this.showExitIntentPopUp, 500)
		if (exitIntentEventBlocked) {
			return
		}
		try {
			if (rootStore.timerStore.events.assistedSaleModalEvent?.canBeTriggered) {
				rootStore.timerStore.events.assistedSaleModalEvent.action()
			}
		} catch (e) {
			datadogReportError(e, 'Exit intent')
		}
	}

	componentDidUpdate() {
		if (sessionStorage.getItem('automated') === 'true') {
			return
		}

		if (window.history.length !== rootStore.settingsStore.windowHistoryLength) {
			rootStore.settingsStore.setWindowHistoryLength(window.history.length)
			trackPageView()
		} else if (window.location.href !== rootStore.settingsStore.windowLocationHref) {
			rootStore.settingsStore.setWindowLocationHref(window.location.href)
			//This will catch users going forward and backward in history
			trackPageView()
		}
	}

	initializeKustomer = () => {
		// should map to keys in ab_testing.ts
		const testMap: { [key: string]: string } = {
			truemedAB: 'Can pay with HSA/FSA',
		}
		const allActiveTests = getCookieABTests()
			.keys.filter((test) => !test.endsWith('_0'))
			.map((test) => test.split('_')[0])

		const kustomerInterval = setInterval(() => {
			if (window.Kustomer !== undefined && document.getElementById('kustomer-ui-sdk-iframe')) {
				let scrollY = 0
				document.getElementById('kustomer-ui-sdk-iframe')?.addEventListener('click', function () {
					trackClickedChat()
				})
				document.getElementById('header-kustomer-btn')?.addEventListener('click', function () {
					const kustomer_iframe = document.getElementById('kustomer-ui-sdk-iframe')
					if (kustomer_iframe) {
						kustomer_iframe.style.visibility = 'visible'
						kustomer_iframe.style.display = 'unset'
					}
					trackClickedChat()
					scrollY = window.scrollY
				})

				window.Kustomer.addListener('onConversationCreate', (res: { conversationId: string }) => {
					window.Kustomer.describeConversation({
						conversationId: res.conversationId,
						customAttributes: {
							chatSourceStr: 'web',
							abTestNotesTxt: allActiveTests.filter((test) => !!testMap[test]).map((test) => testMap[test]),
						},
					})
				})

				window.Kustomer.addListener('onClose', () => {
					if (scrollY !== 0) {
						window.scrollTo(0, scrollY)
					}
					const kustomer_iframe = document.getElementById('kustomer-ui-sdk-iframe')
					if (kustomer_iframe) {
						kustomer_iframe.style.removeProperty('visibility')
					}
				})

				clearInterval(kustomerInterval)
			}
		}, 1000)
	}

	handleRouteChange = () => {
		// When user change page, then snap to top
		rootStore.cartStoreNew.initCart()
		rootStore.navStore.toggle(true)

		if (exitIntentTimer) {
			clearTimeout(exitIntentTimer)
		}
		window.sessionStorage.setItem('block-exit-intent', 'true')
		setTimeout(() => {
			window.sessionStorage.removeItem('block-exit-intent')
		}, EXIT_INTENT_TIMEOUT)

		const fullpath = window.location.href.substr(window.location.origin.length)

		if (this.lastPageTracked !== fullpath) {
			// prevent double tracking bug in Next router when query params are present.
			// Track google page view event
			setTimeout(() => {
				this.trackPageView()
			}, 1000)
		}

		// make page scrollable again (clicking mobilemenu hamburger icon makes it so you cant scroll)
		const nextFirstChild = document.getElementById('__next').firstChild as HTMLElement
		try {
			nextFirstChild.style.setProperty('overflow', 'unset', 'important')
		} catch (e) {
			datadogReportError(e, 'Handle route change')
		}
		trackABtests()

		rootStore.promoStore.setPromoBar(rootStore.settingsStore.currentPage)
	}

	unpauseExitIntent = () => {
		sessionStorage.setItem('pause_exit_intent', '0')
	}

	trackAdBlock() {
		// check localstorage for uuid; if not there, then generate one
		if (localStorage.getItem('eight-uuid')) {
			return
		} else {
			const uuid = getUUID()
			localStorage.setItem('eight-uuid', uuid)
		}
	}

	trackPageView() {
		if (typeof window === 'undefined') {
			return
		}

		const vanity_json = sessionStorage.getItem(VANITY_SESSION_KEY)
		const vanityPage = typeof vanity_json === 'string' ? JSON.parse(vanity_json) : null
		if (vanityPage) {
			const utmMedium = vanityPage?.utm_medium || ''
			const utmSource = vanityPage?.utm_source || ''
			// const utmCampaign = vanityPage?.utm_campaign || ''
			// const utmContent = vanityPage?.utm_content || ''

			set({
				campaignSource: utmSource,
				campaignMedium: utmMedium,
				// campaignName: utmCampaign,
				// campaignContent: utmContent,
				location: window.location.href,
			})
			pageview(window.location.pathname)

			ReactGAV4.set({
				campaignSource: utmSource,
				campaignMedium: utmMedium,
				// campaignName: utmCampaign,
				// campaignContent: utmContent,
				location: window.location.href,
			})

			try {
				updateUserUTMs(utmMedium, utmSource)
			} catch (e) {
				datadogReportError(e, 'Track page view')
			}
		} else {
			set({
				location: window.location.href,
			})
			pageview(window.location.pathname + window.location.search)

			ReactGAV4.set({
				location: window.location.href,
			})
		}
		const fullpath = window.location.href.substr(window.location.origin.length)
		this.lastPageTracked = fullpath

		// track podsights
		try {
			window.pdst?.('view')
		} catch (e) {
			datadogReportError(e, 'Track page view')
		}

		// trackPageLoaded()
	}

	parseUTMinfo = () => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)

		const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
		try {
			utmParams.forEach((utmParam) => {
				if (urlParams.has(utmParam)) {
					sessionStorage.setItem(utmParam, urlParams.get(utmParam))
				}
			})
		} catch (e) {
			datadogReportError(e, 'ParseUTM')
		}

		// handle variation query param for email a/b
		try {
			Object.keys(customQueryParamsToTrackInGA).forEach((utmParam) => {
				const dimension = customQueryParamsToTrackInGA[utmParam]
				if (urlParams.has(utmParam)) {
					window.ga('set', dimension, urlParams.get(utmParam))
				}
			})
		} catch (e) {
			datadogReportError(e, 'ParseUTM')
		}

		if (urlParams.has('email')) {
			sessionStorage.setItem('customer_email', urlParams.get('email'))
		}
	}

	render() {
		const { Component, pageProps, router } = this.props
		initializeStore(pageProps.rootStoreInitializationData)
		const normalizedPath = getNormalizedURL(router)
		rootStore.settingsStore.setCurrentPage(normalizedPath)

		const queryRegion = router.query.locale as RegionShort
		if (rootStore.settingsStore.currentRegion !== queryRegion) {
			if (typeof window !== 'undefined' && window.location.hostname.endsWith('.com.mx')) {
				rootStore.settingsStore.setRegion('mx')
			} else {
				rootStore.settingsStore.setRegion(['', 'us', 'eu', 'uk', 'ca', 'au', 'se', 'no', 'dk', 'ae', 'ch', 'mx'].indexOf(queryRegion) !== -1 ? queryRegion : '')
			}
		}

		if (normalizedPath.startsWith('/blog') && typeof window !== 'undefined') {
			const cfcountry = getCookie('cfcountry')?.toUpperCase()
			if (cfcountry && CF_COUNTRY_MAP[cfcountry] && CF_COUNTRY_MAP[cfcountry] !== rootStore.settingsStore.currentRegion) {
				rootStore.settingsStore.setRegion(CF_COUNTRY_MAP[cfcountry])
			}
		}

		let canonicalUrl = normalizedPath
		switch (router.pathname) {
			case '/[experiments]/[locale]/pod-prospecting':
			case '/[experiments]/[locale]/pod-prospecting-yt':
			case '/[experiments]/[locale]/cooling-sleep':
				canonicalUrl = '/product/pod-cover/'
				break
			// case '/[experiments]/[locale]/[t1]':
			// 	canonicalUrl = '/pod-compare/'
			// 	break
			case '/[experiments]/[locale]':
				canonicalUrl = queryRegion !== 'us' && queryRegion !== '' ? `/${queryRegion}/` : '/'
				break
		}

		rootStore.settingsStore.loadSettings(this.props.pageProps.vanity_data)

		let verifyPassTesting = false
		let automated = false

		if (typeof window !== 'undefined') {
			rootStore.promoStore.initPromo()
			automated = sessionStorage.getItem('automated') === 'true' || window.location.search.includes('automated=true')

			// Impact clickId passing
			const queryParams = QueryString.parse(window.location.search)

			if (queryParams.utm_source === 'Impact' && typeof queryParams.irclickid === 'string' && queryParams.irclickid.length) {
				sessionStorage.setItem('impactClickId', queryParams.irclickid)
			}

			// VerifyPass testing
			const urlParams = new URLSearchParams(window.location.search)
			if (urlParams.has('verifyPass')) {
				verifyPassTesting = true
			}

			const params = new URLSearchParams(window.location.href)
			const shopCode = params.get('code')
			if (shopCode) {
				sessionStorage.setItem('memberShopCode', shopCode)
			}
		}

		const renderCookieConsent =
			typeof window !== 'undefined' &&
			location.hostname !== 'localhost' &&
			!automated &&
			!location.search.includes('member=true') &&
			!location.search.includes('automated=true') &&
			(rootStore.settingsStore.isUK || rootStore.settingsStore.isEULegacy) &&
			!process.env.CI &&
			!window['ReactNativeWebView']

		const primaryEmailCapTriggerPoint = 'calc(100vh + 10px)'
		let hideEcap = false

		if (typeof window !== 'undefined') {
			hideEcap = !!window.sessionStorage.getItem('hide_ecap')
			hideEcap = hideEcap || !!window.localStorage.getItem('authToken')
		}

		const eoyURL = canonicalUrl.includes('eoy20')

		const showWaypoint =
			!canonicalUrl.startsWith('/membership-details') &&
			!canonicalUrl.startsWith('/app-terms-conditions') &&
			!canonicalUrl.startsWith('/aceyoursleep') &&
			!canonicalUrl.startsWith('/sms-terms') &&
			!canonicalUrl.startsWith('/press') &&
			!canonicalUrl.startsWith('/referral-terms-conditions') &&
			!canonicalUrl.startsWith('/terms-and-conditions') &&
			!canonicalUrl.startsWith('/warranty') &&
			!canonicalUrl.startsWith('/404') &&
			!canonicalUrl.startsWith('/blog') &&
			!canonicalUrl.startsWith('/truemed') &&
			!canonicalUrl.startsWith('/pod-quiz') &&
			!canonicalUrl.includes('8ball') &&
			!canonicalUrl.includes('eoy20') &&
			!canonicalUrl.includes('sleepchemistryquiz') &&
			!canonicalUrl.includes('sleepreport') &&
			!canonicalUrl.includes('checkout') &&
			!canonicalUrl.includes('gsap') &&
			!canonicalUrl.startsWith('/affirm') &&
			!canonicalUrl.startsWith('/thank_you') &&
			!canonicalUrl.includes('pod4-') &&
			!canonicalUrl.includes('login') &&
			!canonicalUrl.includes('sleep-elixir-waitlist') &&
			!canonicalUrl.includes('sleep-elixir-management') &&
			!canonicalUrl.includes('athletes') &&
			!hideEcap &&
			!eoyURL

		const calculateDelay = () => {
			const googleLP = /product\/(pod4|pod4ultra)/.test(canonicalUrl)

			if (googleLP) return 5

			return 3
		}

		const delay = calculateDelay()
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
			ScrollTrigger.refresh()
		}

		return (
			<Provider
				rootStore={rootStore}
				promoStore={rootStore.promoStore}
			>
				<div
					onMouseLeave={this.exitIntentEvent}
					onMouseEnter={this.unpauseExitIntent}
					className={NEUE_CLASS}
				>
					<Head>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0"
						/>
						{/* <link
							rel="canonical"
							href={`${SITE_DOMAIN}${canonicalUrl}`}
						/> */}
						<link
							rel="alternate"
							hrefLang="en"
							href={SITE_DOMAIN + `${canonicalUrl.replace(`/${rootStore.settingsStore.currentRegion}`, '')}`}
						/>
						{config.regions
							.filter((it) => {
								const rr = rootStore.settingsStore.currentRegion || 'us'
								return it !== rr
							})
							.map((r) => hreflangMap[r].map((it) => `${r}|${it}`))
							.flat()
							.map((it) => {
								const [r, hreflang] = it.split('|')
								const href = SITE_DOMAIN + `${r === 'us' ? '' : '/' + r}${canonicalUrl.replace(`/${rootStore.settingsStore.currentRegion}`, '')}`
								return (
									<link
										rel="alternate"
										key={`hreflang-${hreflang}`}
										hrefLang={hreflang}
										href={href.endsWith('/') ? href : href + '/'}
									/>
								)
							})}
					</Head>

					{canonicalUrl.indexOf('product') === -1 && (
						<AppMetadata
							path={canonicalUrl}
							key={canonicalUrl}
						/>
					)}

					<ErrorBoundary>
						<Component {...pageProps} />
						<SpeedInsights />
					</ErrorBoundary>

					{showWaypoint && (
						<>
							<PrimaryEmailCapWaypointLazy
								delay={delay}
								top={primaryEmailCapTriggerPoint}
							/>
							<EmailCapContainerLazy />
							<QuizEcapLazy />
						</>
					)}

					{!eoyURL && <LocalizeModalLazy />}

					{(queryRegion === 'eu' || (typeof window !== 'undefined' && window.location.hostname.endsWith('.com.mx')) || rootStore.settingsStore.currentRegion === 'mx') && !eoyURL && (
						<>
							<Script
								strategy="afterInteractive"
								src="https://global.localizecdn.com/localize.js"
								onLoad={() => {
									;(function (a) {
										if (!a.Localize) {
											a.Localize = {}
											for (
												let e = [
														'translate',
														'untranslate',
														'phrase',
														'initialize',
														'translatePage',
														'setLanguage',
														'getLanguage',
														'getSourceLanguage',
														'detectLanguage',
														'getAvailableLanguages',
														'untranslatePage',
														'bootstrap',
														'prefetch',
														'on',
														'off',
														'hideWidget',
														'showWidget',
													],
													t = 0;
												t < e.length;
												t++
											)
												a.Localize[e[t]] = function () {}
										}
									})(window)
									window.Localize?.initialize({ key: '5934xTWw07jZp', rememberLanguage: true, disableWidget: true, autoApprove: true, saveNewPhrases: process.env.IS_PROD === 'true' ? false : true })
									rootStore.settingsStore.setLocalizeReady()
									window.Localize?.on('setLanguage', function () {
										rootStore.settingsStore.setLocalizeReady()
									})
								}}
							/>
						</>
					)}

					{/* <!-- OneTrust Cookies Consent Notice start for eightsleep.com --> */}
					{renderCookieConsent ? (
						<>
							<Script
								strategy="lazyOnload"
								src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
								data-domain-script="efecf6c5-b77e-46fd-9467-8694ffacb5bf"
								id="cookie-consent-script"
							/>
							<Script
								strategy="lazyOnload"
								id="optanon-script"
								dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() { }` }}
							/>
						</>
					) : (
						<></>
					)}

					{!eoyURL && (
						<Script
							strategy="afterInteractive"
							src="https://static.shopmy.us/Affiliates/sms_aff_clicktrack.js"
						/>
					)}

					{!eoyURL && (
						<Script
							id="klaviyo-script"
							src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=QtJCHb"
							strategy="afterInteractive"
							onLoad={loadKlaviyoObject}
						/>
					)}

					{!eoyURL && (
						<Script
							id={'maze-script'}
							dangerouslySetInnerHTML={{
								__html: `(function(m, a, z, e) {var s, t;try {t = m.sessionStorage.getItem("maze-us");} catch (err) {}if (!t) {t = new Date().getTime();try {m.sessionStorage.setItem("maze-us", t);} catch (err) {}} s = a.createElement("script"); s.src = z + "?apiKey=" + e;s.async = true;a.getElementsByTagName("head")[0].appendChild(s);m.mazeUniversalSnippetApiKey = e;})(window, document, "https://snippet.maze.co/maze-universal-loader.js", "d873ea53-673f-4375-ba14-ee832e988bdf");`,
							}}
						/>
					)}

					{process.env.NODE_ENV !== 'development' ? (
						<Script
							strategy="lazyOnload"
							id="gtm-script"
							dangerouslySetInnerHTML={{
								__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
								new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
								j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
								'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','GTM-KR7VQ5S');`,
							}}
						/>
					) : (
						<></>
					)}

					{verifyPassTesting && (
						<Script
							src="https://cdn.verifypass.com/seller/launcher.js"
							strategy="lazyOnload"
						></Script>
					)}

					<Script
						strategy="lazyOnload"
						src="https://eightsleep.imgix.net/browser-detect_26cf8eb8-0c2c-4329-a91c-81b6f2cd6572.js?331315"
					/>

					{!automated && !canonicalUrl.startsWith('/membership-details') && !eoyURL && <SupportChatLazy />}
				</div>
			</Provider>
		)
	}
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const appProps = await App.getInitialProps(appContext)
	const pageContext = appContext.ctx

	const rootStoreInitializationData = {
		query: pageContext.query,
		pathname: pageContext.pathname,
		asPath: pageContext.asPath,
	} as RootStoreInitializationData

	return {
		...appProps,
		pageProps: {
			rootStoreInitializationData,
		},
	}
}
