import React, { FC, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './Video.module.scss'

interface Props {
	thumb?: string
	src: string
	caption: string
	width?: string
	tabIndex?: number
	playIcon?: string
	pauseIcon?: string
	showControls?: boolean
	className?: string
	autoplay?: boolean
	muted?: boolean
	nativeControls?: boolean
	preload?: string

	noLoop?: boolean

	onPlay?: () => void
	onPause?: () => void

	controlsOnPlay?: boolean
	id?: string
}

type ButtonLabel = 'Play' | 'Pause'

export const Video: FC<Props> = (props) => {
	const {
		tabIndex = -1,
		width = '100%',
		showControls = false,
		src = 'https://eight-eightsleep.s3.amazonaws.com/app_hero_desktop.mp4',
		thumb = 'https://eightsleep.imgix.net/app-hero-bg.jpg?v=1586425953',
		playIcon = 'https://eightsleep.imgix.net/playbuttonellipse.png?v=1565125821',
		pauseIcon = 'https://eightsleep.imgix.net/Vector_1117d1cc-7301-4c47-944c-c3451f21d9d2.png?v=1612256529',
		id,
	} = props

	const vidRef = useRef<HTMLVideoElement>(null)
	const [buttonLabel, setButtonLabel] = useState<ButtonLabel>('Play')

	function handleClickPlayPause() {
		if (!vidRef || !vidRef.current) {
			return
		}
		const isPlaying = !vidRef.current.paused && !vidRef.current.ended
		if (isPlaying) {
			vidRef.current.pause()
		} else {
			vidRef.current.play()
		}
	}

	function handleOnPlay() {
		setButtonLabel('Pause')
		if (props.onPlay) props.onPlay()
	}

	function handleOnPause() {
		setButtonLabel('Play')
		if (props.onPause) props.onPause()
	}

	let _showControls = props.nativeControls

	if (props.controlsOnPlay && buttonLabel === 'Pause') {
		_showControls = true
	}

	return (
		<figure
			className={cx(styles.c_video, 'video-wrapper', props.className)}
			style={{
				['--play-icon' as any]: `url("${playIcon}")`,
				['--pause-icon' as any]: `url("${pauseIcon}")`,
			}}
		>
			<video
				ref={vidRef}
				preload={props.preload ?? 'auto'}
				loop={!props.noLoop}
				playsInline={true}
				autoPlay={props.autoplay ?? true}
				// controls={props.nativeControls ? true : props.showControls && buttonLabel === 'Pause' ? true : false}
				controls={_showControls}
				tabIndex={tabIndex}
				width={width}
				poster={thumb}
				id={id}
				src={src}
				muted={props.muted ?? true}
				onPlay={handleOnPlay}
				onPause={handleOnPause}
				disablePictureInPicture
				disableRemotePlayback
			></video>
			{!props.nativeControls && showControls ? (
				<button
					type="button"
					id={id + '-button'}
					onClick={handleClickPlayPause}
					aria-label={buttonLabel}
					className={`${styles.controls} ${buttonLabel == 'Play' ? 'play' : 'pause'}`}
				>
					{buttonLabel}
				</button>
			) : (
				<button
					type="button"
					id={id + '-button'}
					onClick={handleClickPlayPause}
					className={`${styles.a_button} ${buttonLabel == 'Play' ? 'play' : 'pause'}`}
				></button>
			)}
			<figcaption className="vh">{props.caption}</figcaption>
		</figure>
	)
}
