import { getRegionCoverName, PriceManager, PriceStore, SubscriptionKeys } from 'prices'
import { Content, ContentWithSteps, Selection, ShopState } from 'components/WebEv/Shop/types'
import { BundleProducts, MattressProducts } from 'products/allProducts'
import { Currency } from 'prices/types'
import { RootStore } from 'stores'
import { regionIsMetric, RegionShort } from 'utils/internationalization'
import { ProductSelectFields } from 'products/types'
import { MattressModal, MembershipModal, ModelModal, SizesModal } from '../Modals'
import { getAutopilotABStringMap } from 'utils/autopilotABUtil'

export const getRegionalCoverShop = (rootStore: RootStore, pagePath?: string): ShopState => {
	const priceStore = rootStore.priceStore
	const isMember = rootStore.promoStore.discountCode === 'members'
	const isMetric = regionIsMetric(rootStore.settingsStore.currentRegion)

	const memberShopPage = pagePath && pagePath.includes('eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208')

	let selections = isMember ? [getGiftFlowCards()] : []
	selections = [...selections, getModels(memberShopPage, isMetric), getSizes(rootStore)]

	const carouselSlides: ContentWithSteps[] = [modelStep, getSizeStep(priceStore.currency)]

	const showSubscriptionPlans = typeof window === 'undefined' || !isMember
	if (showSubscriptionPlans) {
		selections.push(getMembershipCards(priceStore.currency, rootStore.settingsStore.currentPage))
		carouselSlides.push(getMembershipStep(priceStore.currency))
	}

	const sellsBundle = rootStore.productStore.regionSellsBundle()
	const sellsMattress = rootStore.productStore.regionSellsMattress() && !rootStore.promoStore.vanityPreventsMattress

	if (sellsBundle) {
		selections.push(getSleepStarterUpsell(priceStore))
		carouselSlides.push(bundleStep)
	}

	if (sellsMattress) {
		selections.push(getMattressUpsell(priceStore))
		carouselSlides.push(mattressStep)
	}

	selections.push(getCheckoutSelection())
	carouselSlides.push(checkoutStep)

	carouselSlides.push(explainerVideo)

	const desktopStickyContent: Content = {
		type: 'carousel',
		data: {
			slides: carouselSlides,
		},
	}

	const mobileSelectionPairings: ContentWithSteps[] = [
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 0,
				},
			},
			associatedSteps: ['model'],
		},
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 1,
				},
			},
			associatedSteps: ['size'],
		},
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 2,
				},
			},
			associatedSteps: ['membership'],
		},
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 3,
				},
			},
			associatedSteps: ['mattress'],
		},
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 4,
				},
			},
			associatedSteps: ['sleepessentialsbundle'],
		},
		{
			content: {
				type: 'carousel',
				data: {
					slides: carouselSlides,
					startingSlide: 5,
				},
			},
			associatedSteps: ['checkout'],
		},
	]

	return {
		activeSelections: {
			giftflow: {
				field: 'gift-flow',
				value: 'me',
			},
			model: {
				field: 'product',
				value: 'pod-pro-cover-3-perfect',
			},
			membership: {
				field: 'membership',
				value: 'plus',
			},
			mattress: {
				field: 'mattress-upsell',
				value: 'false',
			},
			sleepessentialsbundle: {
				field: 'sleep-starter-bundle-upsell',
				value: 'false',
			},
		},
		data: {
			selections: selections,
			desktopStickyContent: desktopStickyContent,
			mobileSelectionPairings: mobileSelectionPairings,
		},
		discount: {
			type: 'amount',
			value: priceStore.coverDiscountNumber,
		},
		modal: null,
		prevModal: null,
		price: priceStore.coverPriceNumber,
		productName: 'Pod Cover',
		strikePrice: priceStore.coverComparePriceNumber,
		floatingCheckoutOpen: false,
		orderNowMessage: 'Order now. Offer ends soon.',
		currency: priceStore.currency,
		giftMembership: false,
	}
}

const getModels = (memberShop: boolean, isMetric: boolean): Selection => {
	const selections = []

	if (!memberShop) {
		selections.push({
			title: 'Pod 2 Cover',
			id: 'pod-2-pro-cover',
			description: isMetric ? 'Fits 25 - 28 cm mattress' : 'Fits 10 - 11" mattress',
		})
	}

	selections.push({
		title: 'Pod 3 Cover',
		id: 'pod-pro-cover-3-standard',
		description: isMetric ? 'Fits 25 - 28 cm mattress' : 'Fits 10 - 11" mattress',
	})

	selections.push({
		badgeText: 'Popular',
		title: 'Pod 3 Cover w/ PerfectFit',
		id: 'pod-pro-cover-3-perfect',
		description: 'Fits all beds + increased comfort',
	})

	return {
		type: 'cards',
		id: 'model',
		title: 'Model',
		description: "Select the one that's best for you.",

		modal: {
			triggerText: 'Need help choosing a model?',
			modal: <ModelModal />,
		},
		field: 'product',
		data: {
			labels: selections,
		},
	}
}
const getSizes = (rootStore: RootStore): Selection => {
	const getLabels = (region: RegionShort): ProductSelectFields[] => {
		switch (region) {
			case '':
			case 'us':
			default:
				return [
					{ title: 'Full', id: 'full' },
					{ title: 'Queen', id: 'queen' },
					{ title: 'King', id: 'king' },
					{ title: 'Cali King', id: 'caliking' },
				]
			case 'ca':
				return [
					{ id: 'cafull', title: 'CA Full' },
					{ id: 'caqueen', title: 'CA Queen' },
					{ id: 'caking', title: 'CA King' },
				]
			case 'eu':
				return [
					{ id: 'eudouble', title: 'Double (135x190\xA0cm)' },
					{ id: 'eulargedouble', title: 'Large Double (160x200\xA0cm)' },
					{ id: 'eusuperking', title: 'Super King (180x200\xA0cm)' },
				]
			case 'uk':
				return [
					{ id: 'ukdouble', title: 'UK Double (135x190\xA0cm)' },
					{ id: 'ukking', title: 'UK King (150x200\xA0cm)' },
					{ id: 'uksuperking', title: 'UK Super King (180x200\xA0cm)' },
				]
			case 'au':
				return [
					{ id: 'audouble', title: 'AU Double' },
					{ id: 'auqueen', title: 'AU Queen' },
					{ id: 'auking', title: 'AU King' },
				]
			case 'se':
				return [
					{ id: 'sedouble', title: 'Double (135x190\xA0cm)' },
					{ id: 'seking', title: 'Large Double (160x200\xA0cm)' },
					{ id: 'sesuperking', title: 'Super King (180x200\xA0cm)' },
				]
			case 'dk':
				return [
					{ id: 'dkdouble', title: 'Double (135x190\xA0cm)' },
					{ id: 'dkking', title: 'Large Double (160x200\xA0cm)' },
					{ id: 'dksuperking', title: 'Super King (180x200\xA0cm)' },
				]
			case 'ae':
				return [
					{ id: 'aequeen', title: 'Queen (135x190\xA0cm)' },
					{ id: 'aeking', title: 'King (160x200\xA0cm)' },
					{ id: 'aeemperor', title: 'Emperor (180x200\xA0cm)' },
				]
			case 'ch':
				return [
					{ id: 'chdouble', title: 'Double (135x190\xA0cm)' },
					{ id: 'chlargedouble', title: 'Large Double (160x200\xA0cm)' },
					{ id: 'chusuperking', title: 'Super King (180x200\xA0cm)' },
					{ id: 'chemporer', title: 'Emperor (200x200\xA0cm)' },
				]
			case 'mx':
				return [
					{ id: 'mxmatrimonial', title: 'Matrimonial (135x190\xA0cm)' },
					{ id: 'mxqueen', title: 'Queen (150x200\xA0cm)' },
					{ id: 'mxking', title: 'King (180x200\xA0cm)' },
				]
		}
	}

	return {
		type: 'cards',
		id: 'size',
		title: 'Size',
		description: 'What size mattress will you be placing the Pod Cover on?',
		field: 'variant',

		modal: {
			triggerText: 'Need help choosing a size?',
			modal: <SizesModal />,
		},
		data: {
			labels: getLabels(rootStore.settingsStore.currentRegion),
		},
	}
}

export const getMembershipCards = (currency: Currency, pagePath: string, description?: string): Selection => {
	const getMembershipSubtitleText = (susbcriptionId: SubscriptionKeys) => {
		return `${PriceManager.getCurrencySubscriptionPrice(susbcriptionId, currency)}/mo`
	}

	return {
		type: 'cards',
		id: 'membership',
		title: 'Autopilot',
		description: description ?? getAutopilotABStringMap('membership_description'),
		field: 'membership',

		modal: {
			triggerText: 'Need help choosing a plan?',
			modal: <MembershipModal />,
		},
		data: {
			labels: [
				{
					title: 'Standard',
					id: 'pro',
					subtitle: getMembershipSubtitleText('8-pro-membership'),
					subtitle2: `${PriceManager.getCurrencySubscriptionPriceYearly('8-pro-membership', currency)} billed annually`,
					description: '<ul><li>Automatic temperature</li><li>Sleep & health reports</li><li>Vibration & thermal alarms</li></ul>',
				},
				{
					badgeText: 'Popular',
					title: 'Enhanced',
					id: 'plus',
					subtitle: getMembershipSubtitleText('8-plus-membership'),
					subtitle2: `${PriceManager.getCurrencySubscriptionPriceYearly('8-plus-membership', currency)} billed annually`,
					description: '<ul><li>Automatic temperature</li><li>Sleep & health reports</li><li>Vibration & thermal alarms</li><li>Extended 5-year warranty</li></ul>',
				},
			],
		},
	}
}

export const getGiftFlowCards = (): Selection => {
	return {
		type: 'gift',
		id: 'giftflow',
		title: 'Member',
		description: 'Who will be using this Pod Cover?',
		field: 'gift-flow',
		data: {
			labels: [
				{
					title: 'Me',
					id: 'me',
					subtitle: '',
					subtitle2: 'This Pod will be added to your existing membership',
				},
				{
					title: 'Someone else',
					id: 'someone-else',
					subtitle: '',
					subtitle2: `You will purchase the first year of membership/mo`,
				},
			],
		},
	}
}

export const getMattressUpsell = (priceStore: PriceStore): Selection => {
	const podMattressPrices = priceStore.currency === 'USD' ? priceStore.withPrices(MattressProducts.FiveLayerDiscounted) : priceStore.withPrices(MattressProducts.ThreeLayer)
	const region = PriceManager.getCurrencyRegion(priceStore.currency)
	const defaultVariant = getRegionCoverName(region)
	const mattressPrice = podMattressPrices.variants[defaultVariant].prices.price / 100
	const mattressStrikePrice = podMattressPrices.variants[defaultVariant].prices.comparePrice / 100

	const title = 'Add a mattress and save more'
	const description = `With our ${priceStore.currency === 'USD' ? '5' : '3'}-layer mattress, you can achieve the perfect mix of temperature control and comfort.`

	return {
		type: 'upsell',
		id: 'mattress',
		title: title,
		description: description,
		field: 'mattress-upsell',
		optional: true,
		modal: {
			triggerText: 'Why add a mattress?',
			modal: <MattressModal />,
		},
		data: {
			hiddenProductName: 'Mattress',
			productTitle: `${priceStore.currency === 'CAD' ? 3 : 5}-layer Mattress`,
			productDescription: `Achieve the perfect mix of temperature control and comfort`,
			image: {
				src: 'https://eightsleep.imgix.net/mattress_upsell_01323226-ba00-411f-9170-2b14f72cd5d1.png?v=1692905045',
				alt: 'A photo of the Pod mattress',
			},

			priceTitle: 'Add the mattress',
			checkboxCopy: 'Add to order',

			price: mattressPrice,
			strikePrice: mattressStrikePrice,
			discount: {
				value: mattressStrikePrice - mattressPrice,
				type: 'amount',
			},
			icons: [
				{
					image: 'https://eightsleep.imgix.net/firmess.svg?v=1699556649',
					text: 'Medium firmness with spinal support',
				},
				{
					image: 'https://eightsleep.imgix.net/bed_e25e7971-b257-4f35-9f45-cf5a2058b7f9.png?v=1699556649',
					text: '11” mattress height',
				},
				{
					image: 'https://eightsleep.imgix.net/vibration.png?v=1699556649',
					text: 'Pressure relieving materials',
				},
				{
					image: 'https://eightsleep.imgix.net/warm.png?v=1699556649',
					text: 'Excellent airflow for cooler sleep',
				},
			],
		},
	}
}

export const getSleepStarterUpsell = (priceStore: PriceStore): Selection => {
	const bundlePrices = priceStore.withPrices(BundleProducts.SleepEssentialBundle)
	const region = PriceManager.getCurrencyRegion(priceStore.currency)
	const defaultVariant = getRegionCoverName(region)
	const price = bundlePrices.variants[defaultVariant].prices.price / 100
	const strikePrice = bundlePrices.variants[defaultVariant].prices.comparePrice / 100
	const description = BundleProducts.SleepEssentialBundle.description.join('')

	return {
		type: 'upsell',
		id: 'sleepessentialsbundle',
		title: 'Add an accessory and save more',
		description: 'Make the most of your sleep with extras designed for the Pod',
		field: 'sleep-starter-bundle-upsell',
		optional: true,
		data: {
			hiddenProductName: 'Sleep Essentials Bundle Upsell',
			productTitle: 'Sleep Essentials Bundle',
			productDescription: description,
			image: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-essentials-bundle-2024-transparent.png',
				alt: description,
			},

			priceTitle: 'Sleep Essentials Bundle',
			checkboxCopy: 'Add to order',

			price: price,
			strikePrice: strikePrice,
			discount: {
				value: strikePrice - price,
				type: 'amount',
			},
		},
	}
}

const getCheckoutSelection = (): Selection => {
	return {
		type: 'checkout',
		id: 'checkout',
		title: 'Checkout',
		description: 'Filler description',
		field: 'checkout',
		optional: true,
		data: {
			checkoutButtonText: 'Checkout',
		},
		hideOn: ['mobile', 'tablet'],
	}
}

const modelStep: ContentWithSteps = {
	content: {
		type: 'variable-image',
		data: {
			images: [
				{
					src: 'https://eightsleep.imgix.net/pc2.jpg?v=1694726113',
					alt: 'The Pod Cover 2 and Hub on an empty background',
					selectionValues: ['pod-2-pro-cover'],
				},
				{
					src: 'https://eightsleep.imgix.net/pc3_standard.jpg?v=1694726114',
					alt: 'The Pod Cover 3 and Hub on an empty background',
					selectionValues: ['pod-pro-cover-3-standard'],
				},
				{
					src: 'https://eightsleep.imgix.net/pc3_pf.jpg?v=1694726332',
					alt: 'The Pod Cover 3 Perfect Fit and Hub on an empty background',
					selectionValues: ['pod-pro-cover-3-perfect'],
				},
			],
			defaultImage: {
				src: 'https://eightsleep.imgix.net/pc3_pf.jpg?v=1694726332',
				alt: 'The Pod Cover 3 Perfect Fit and Hub on an empty background',
			},
		},
	},
	associatedSteps: ['model'],
}

const getSizeStep = (currency: Currency): ContentWithSteps => {
	let video = 'https://d115sb6i1ixllw.cloudfront.net/assets/Summer%20Bedroom%20Coverdrop%20Video.mp4'
	let poster = 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/summer.jpg'

	if (currency === 'AUD') {
		// Southern hemisphere
		video = 'https://d115sb6i1ixllw.cloudfront.net/assets/37b62429230541f4a834aff6e94c6072.mp4'
		poster = 'https://eightsleep.imgix.net/Summer_Carousel.jpg?v=1699555274'
	}

	return {
		content: {
			type: 'video-gif',
			data: {
				src: video,
				poster: poster,
			},
		},
		associatedSteps: ['size'],
		whiteDots: true,
	}
}

export const getMembershipStep = (currency: Currency): ContentWithSteps => {
	let image = 'https://eightsleep.imgix.net/warm-shop-pdp-phone.jpg?v=1699547294'
	if (currency === 'AUD') {
		// Southern hemisphere
		image = 'https://eightsleep.imgix.net/phone-slide.jpg?v=1694031262'
	}

	return {
		content: {
			type: 'image',
			data: {
				src: image,
				alt: 'A phone with the Eight Sleep app open',
			},
		},
		associatedSteps: ['membership'],
	}
}

const mattressStep: ContentWithSteps = {
	content: {
		type: 'video-gif',
		data: {
			src: 'https://eight-eightsleep.s3.amazonaws.com/cover_falling_white.mp4',
			poster: 'https://eightsleep.imgix.net/dropcover-output_image.jpg?v=1694789693',
		},
	},
	associatedSteps: ['mattress'],
}

const bundleStep: ContentWithSteps = {
	content: {
		type: 'image',
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pod3+Protector+Pillows.jpg',
			alt: 'The Pod Cover with the Sleep Essentials Bundle',
		},
	},
	associatedSteps: ['sleepessentialsbundle'],
}

const checkoutStep: ContentWithSteps = {
	content: {
		type: 'whats-included',
		data: {
			items: ['mattress', 'cover', 'hub', 'power-cord'],
		},
	},
	associatedSteps: ['checkout'],
}

const explainerVideo: ContentWithSteps = {
	content: {
		type: 'video',
		data: {
			src: 'https://d115sb6i1ixllw.cloudfront.net/assets/6b0035ec8e394a648a1e72d132287746.mp4',
			poster: 'https://eightsleep.imgix.net/member_upgrade_video_poster.jpg?v=165885941',
		},
	},
	whiteDots: true,
	associatedSteps: [],
}
