import { useEffect, useState } from 'react'

/**
 * Hook that will return the size of the window on window resize
 */
export function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: -1,
		height: -1,
	})

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener('resize', handleResize)
		// Call handler right away so state gets updated with initial window size
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}
