import { FC, useEffect, useRef, useState, memo } from 'react'
import styles from './UGCVideoWall.module.scss'
import { UGCVideoWallProps, DEFAULT_VIDEOS, VideoData } from './UGCVideoWall.types'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'
import { Img, srcToImgix } from 'components/basic/Img'
import { useSlider } from 'components/_hooks/useSlider'
import useABTestVariant from 'hooks/useABTestVariant'
import { ugcAB } from 'ab_testing'

export const UGCVideoWall: FC<UGCVideoWallProps> = (props) => {
	const { videos = DEFAULT_VIDEOS } = props
	const videoRefs = useRef(new Map<number, HTMLVideoElement>())
	const [playingVideos, setPlayingVideos] = useState(new Set<number>())
	const containerRef = useRef<HTMLElement>(null)
	const hasAutoPlayed = useRef(false)

	const [isAutoplaying, setIsAutoplaying] = useState(false)

	const [sliderRef] = useSlider({ refreshToken: videos.length, throwResistance: 3000 })

	const isUGCAB = useABTestVariant(ugcAB, 1)

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !hasAutoPlayed.current) {
						// Start playing the first video when component comes into view
						const firstVideo = videoRefs.current.get(0)
						if (firstVideo) {
							firstVideo.play()
							setPlayingVideos(new Set([0]))
							hasAutoPlayed.current = true
							setIsAutoplaying(true)
						}
					} else if (entry.intersectionRatio === 0) {
						// Only clear videos if we're completely out of view
						setPlayingVideos(new Set())
						hasAutoPlayed.current = false
					}
				})
			},
			{
				threshold: [0, 0.1],
			}
		)

		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [])

	const handleVideoPlay = (videoId: number) => {
		if (playingVideos.has(videoId) && playingVideos.size === 1) {
			return
		}

		setPlayingVideos(new Set([videoId]))
	}

	const handleVideoPause = (videoId: number) => {
		if (!playingVideos.has(videoId)) {
			return
		}

		const newPlayingVideos = new Set(playingVideos)
		newPlayingVideos.delete(videoId)
		setPlayingVideos(newPlayingVideos)
	}

	const sliderChildren = videos.map((video, index) => (
		<li
			key={index}
			className={styles.video_grid_item}
		>
			<VideoTile
				video={video}
				videoId={index}
				isPlaying={playingVideos.has(index)}
				onPlay={() => handleVideoPlay(index)}
				onPause={() => handleVideoPause(index)}
				onVideoRef={(ref) => {
					if (ref) {
						videoRefs.current.set(index, ref)
					} else {
						videoRefs.current.delete(index)
					}
				}}
				onClick={() => setIsAutoplaying(false)}
				showOverlayOnPlay={isAutoplaying}
			/>
		</li>
	))

	if (!isUGCAB) {
		return null
	}

	return (
		<section
			ref={containerRef}
			className={styles.container}
		>
			<div className={styles.wrapper}>
				<header className={styles.header}>
					<Type.Headline2 className={styles.headline}>Health experts swear by the Pod</Type.Headline2>
				</header>

				<ul
					ref={sliderRef}
					className={styles.video_grid_mobile}
				>
					{sliderChildren}
				</ul>
				<ul className={styles.video_grid_desktop}>{sliderChildren}</ul>
			</div>
		</section>
	)
}

interface VideoTileProps {
	video: VideoData
	videoId: number
	isPlaying: boolean

	showOverlayOnPlay?: boolean

	onPlay: () => void
	onPause: () => void
	onVideoRef: (ref: HTMLVideoElement | null) => void

	onClick: () => void
}

const VideoTile: FC<VideoTileProps> = memo(
	(props) => {
		const { video, isPlaying, showOverlayOnPlay, onPlay, onPause, onVideoRef, onClick } = props
		const videoRef = useRef<HTMLVideoElement>(null)
		const [isMuted, setIsMuted] = useState(true)
		const lookingForSeek = useRef(false)
		const foundSeek = useRef(false)

		useEffect(() => {
			if (isPlaying) {
				videoRef.current.currentTime = 0
				videoRef.current.play()
			} else {
				videoRef.current.pause()
				setIsMuted(true)
			}
		}, [isPlaying])

		const handlePlayClick = () => {
			onClick()
			if (videoRef.current) {
				if (!isPlaying) {
					setIsMuted(false)
					onPlay()
				} else {
					onPause()
				}
			}
		}

		const handleMuteClick = () => {
			if (videoRef.current) {
				videoRef.current.muted = !isMuted
				setIsMuted(!isMuted)
			}
		}

		return (
			<div className={styles.videoWrapper}>
				<video
					ref={(ref) => {
						videoRef.current = ref
						onVideoRef(ref)
					}}
					className={styles.video}
					src={video.videoUrl}
					poster={srcToImgix(video.posterUrl)}
					playsInline
					disablePictureInPicture
					disableRemotePlayback
					muted={isMuted}
					preload="none"
					controls={isPlaying}
					onEnded={onPause}
					onSeeking={() => {
						if (lookingForSeek.current) {
							foundSeek.current = true
						}
					}}
					onSeeked={() => {
						if (lookingForSeek.current) {
							foundSeek.current = true
						}
					}}
					onPause={() => {
						lookingForSeek.current = true
						setTimeout(() => {
							if (!foundSeek.current) {
								onPause()
							}

							foundSeek.current = false
							lookingForSeek.current = false
						}, 250)
					}}
					onPlay={onPlay}
				/>
				{!isPlaying && (
					<Img
						src={video.posterUrl}
						alt={`${video.expertName} thumbnail`}
						className={styles.posterImage}
					/>
				)}

				{/* {isPlaying && (
					<Button.Empty
						id={`mute-${video.expertName.toLowerCase().replace(/\s+/g, '-')}`}
						onClick={handleMuteClick}
						className={styles.muteButton}
					>
						<Icon name={isMuted ? 'SoundOffDark' : 'SoundDark'} />
					</Button.Empty>
				)} */}
				{!isPlaying && (
					<Button.Empty
						id={`play-${video.expertName.toLowerCase().replace(/\s+/g, '-')}`}
						onClick={handlePlayClick}
						className={styles.playButton}
					>
						<span className={styles.play_button_inner}>
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.82324 2.3904V18.7814C2.82324 19.6937 3.80561 20.2658 4.59558 19.8135L18.9114 11.6181C19.7081 11.162 19.7081 10.0098 18.9114 9.55368L4.59558 1.35828C3.80561 0.905999 2.82324 1.47811 2.82324 2.3904Z"
									fill="white"
									stroke="white"
									strokeWidth="2.08571"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</Button.Empty>
				)}
				{(!isPlaying || showOverlayOnPlay) && <div className={styles.overlay} />}
				{(!isPlaying || showOverlayOnPlay) && (
					<div className={styles.expertInfo}>
						<Type.Headline5 className={styles.expertName}>{video.expertName}</Type.Headline5>
						<Type.Body2 className={styles.expertTitle}>{video.expertTitle}</Type.Body2>
					</div>
				)}
			</div>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.isPlaying === nextProps.isPlaying && prevProps.videoId === nextProps.videoId && prevProps.video === nextProps.video
	}
)
