import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import styles from './NavBar.module.scss'
import { Img } from 'components/basic/Img'
import cx from 'classnames'
import { extractRegionFromPath, redirectToRegion, RegionShort } from 'utils/internationalization'
import { A } from 'components/basic/A'
import { Button } from 'components/basic/Button'
import { amClickHamburgerABC } from 'events/amplitude'
import { trackClickNavLink } from 'events/index'
import { useRouter } from 'next/router'
import { PromoBar } from './PromoBar'
import { NavBarItem, NavBarItemProps } from './NavBarItem'
import { MobileNav } from './MobileNav'
import { Button as Button2023, Button as NewButton } from 'components/WebEv/Buttons'
import { chat, Icon } from 'components/Icon'
import { Icon as PhantomIcon } from 'components/Phantom/Icon'
import { getNormalizedURL } from 'components/_utils/urlUtils'
import { ClientOnly } from 'components/ClientOnly'
import { CountryPicker } from './CountryPicker'
import { LoadingSkeleton } from 'components/LoadingSkeleton'

export interface NavBarProps {
	promo_bar_text?: string
	promo_bar_href?: string
	promo_bar_date?: Date | string
	promo_bar_click?: () => void

	ctaVisible: boolean

	region: RegionShort

	cartClick: () => void
	onPickerClick?: () => void

	numberOfCartItems: number
	excludeLinks: boolean
	onlyLogo: boolean
	activeTimer: boolean
	showTimer: boolean
	ctaLabel: string
	saleName: string

	ctaAnchorHref?: string

	transparency: boolean
	isCoverLPAB?: boolean
	isVanity?: boolean
	isCoverOnlyAB?: boolean

	renderCountryPicker?: boolean
}

// TODO clean up into something nicer that isn't just copying EmailCapModalEvent? or is this fine
const promoBarExcludedPages = [
	'/travel',
	'/login',
	'/hosts',
	'/referral',
	'/mercedesamgf1',
	'/brooke',
	'/travelerssleepguide',
	'/referred',
	'/member-referrals',
	'/hubermanvip',
	'/summeronice',
	'/terms-and-conditions',
	'/app-terms-conditions',
	'/sms-terms',
	'/warranty',
	'/referral-terms-conditions',
	'/return-policy',
	'/press',
	'/sleep-elixir-waitlist',
]

const navLinksExcludedPages = [
	'/member-accessories',
	'/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208',
	'/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e92082',
	'/product/dce234937b8d54e2r417b318707ea9713e6087b402b40670319c4c1e166e9101',
	'member-accessories=1',
]

const transparentPages = [
	// '/pod-prospecting',
	'/pod-compare',
	'/cooling-sleep',
	'/notboring-prospecting',
	'/pod-quiz',
	// '/login',
	// '/pod-cover',
	'/tv',
	'/hyperice',
	'/higherdose',
	'/financing',
	'/strava',
	'/paceline',
	'/strava/april',
	'/strava/summerofchampions',
	'/strava/finalsprint',
	'/strava/risefor2025',
	'/athletes',
	'/autopilot',
	'/blog',
	'/blog/fitness',
	'/blog/sleep',
	'/blog/wellness',
	'/blog/science',
	'/terms-and-conditions',
	'/app-terms-conditions',
	'/sms-terms',
	'/press',
	'/warranty',
	'/referral-terms-conditions',
	'/return-policy',
	'/bed-cooling',
	'/bed-cooling-hsa',
	'/science',
	'/truemed',
]

const floatingPages = ['/aceyoursleep']

const staticNavMobilePages = ['/product/pod-cover', '/login']

function getCart(ctaVisible: boolean, numberOfCartItems: number, cartClick: () => void) {
	if (numberOfCartItems === 0) return null
	return (
		<div className={styles.cart_container}>
			<Button
				unstyled
				className={styles.cart_icon}
				onClick={cartClick}
				id={'cart-open-link'}
				content={
					<>
						<Img
							src={'https://eightsleep.imgix.net/shop_cart_desktop.svg?v=1622155699'}
							alt={'Cart Icon'}
						/>
						{numberOfCartItems > 0 ? <div className={styles.cart_number}>{numberOfCartItems}</div> : null}
					</>
				}
			/>
		</div>
	)
}

export const NavBar: FC<NavBarProps> = (props) => {
	const [mobileNavOpen, setMobileNavOpen] = useState(false)
	const [mounted, setMounted] = useState(false)
	const [promoBarClosed, setPromoBarClosed] = useState(false)
	const router = useRouter()

	useEffect(() => {
		setMounted(true)
	}, [props.isVanity])

	const pathWithoutPrefix = router.asPath.replace(`/${router.query.locale ?? extractRegionFromPath(router.asPath)}`, '')
	const onlyLogo = props.onlyLogo
	const ctaVisible = props.ctaVisible

	const handlePromoBarClosed = (closed: boolean) => {
		setPromoBarClosed(closed)
		const PROMO_BAR_HEIGHT = 42
		document.documentElement.style.setProperty('--promo-bar-translate', `${closed ? -PROMO_BAR_HEIGHT : 0}px`)
	}

	const handleSetRegion = (region: RegionShort) => {
		redirectToRegion(region, router)
	}

	const openMobileNav = () => {
		if (!mobileNavOpen) {
			setMobileNavOpen(true)
			amClickHamburgerABC('Open')
		}
	}

	const closeMobileNav = () => {
		if (mobileNavOpen) {
			setMobileNavOpen(false)
			amClickHamburgerABC('Close')
		}
	}

	const renderCTA = (transparent: boolean) => {
		if (!ctaVisible) return null

		if (transparent) {
			return (
				<Button2023.Outline
					className={cx(onlyLogo && props.numberOfCartItems === 0 && styles.right_align, styles.cta)}
					href={'/product/pod-cover'}
					id={'nav-get-pod-btn'}
					ariaLabel={props.ctaLabel}
					size={'compact'}
				>
					{props.ctaLabel}
				</Button2023.Outline>
			)
		}

		return (
			<Button2023.Secondary
				className={cx(onlyLogo && props.numberOfCartItems === 0 && styles.right_align, styles.cta)}
				href={'/product/pod-cover'}
				id={'nav-get-pod-btn'}
				ariaLabel={props.ctaLabel}
				size={'compact'}
			>
				{props.ctaLabel}
			</Button2023.Secondary>
		)
	}

	const { leftSideMenus, rightSideMenus, subNavMenus } = router.asPath.startsWith('/blog') ? getBlogMenus() : getMenus()
	const menus = [...leftSideMenus, ...rightSideMenus]

	const normalizedPath = getNormalizedURL(router)
	const pathKey = normalizedPath.split('?')[0]?.replaceAll('/', '')

	const promoBarVisible = !isPathInExcludedPages(normalizedPath.split('?')[0], promoBarExcludedPages) && router.query.utm_source !== 'app'
	const transparencyAllowed = isPathInExcludedPages(normalizedPath.split('?')[0], transparentPages, true)
	const floatingNav = isPathInExcludedPages(normalizedPath.split('?')[0], floatingPages) || normalizedPath.split('?')[0] === '/' || transparencyAllowed

	const staticNavMobile = isPathInExcludedPages(normalizedPath.split('?')[0], staticNavMobilePages)

	const hideSpacerDesktop = shouldHideDesktopSpacer(normalizedPath)

	const hideSpacerMobile = staticNavMobile || !promoBarVisible

	const hideNavLinks = navLinksExcludedPages.some((it) => normalizedPath.includes(it)) || props.excludeLinks

	const hideMobileNavLinks = true //isSubnavigationAB || navLinksExcludedPages.some((it) => normalizedPath.includes(it)) || mobileOnlyExcludedLinksPages.some((it) => pathWithoutPrefix === it) || props.excludeLinks

	const _transparent = false //scrollTransparent && transparencyAllowed && props.transparency

	const showCountdown = props.promo_bar_date && promoBarVisible && props.activeTimer

	let numberOfCTAItems = mounted ? 0 : 1 // Account for loading skeleton
	if (props.ctaVisible) numberOfCTAItems++
	if (props.renderCountryPicker) numberOfCTAItems++
	if (props.numberOfCartItems > 0) numberOfCTAItems++

	return (
		<>
			<header
				className={cx(styles.container, { [styles.static_nav_mobile]: staticNavMobile })}
				tabIndex={-1}
				id="header"
			>
				<AccessibilityLinks />

				<div
					className={styles.nav_container}
					style={{
						backgroundColor: _transparent ? 'transparent' : 'black',
					}}
				>
					<PromoBar
						promo_bar_date={props.promo_bar_date}
						promo_bar_href={props.promo_bar_href}
						promo_bar_text={props.promo_bar_text}
						promo_bar_click={props.promo_bar_click}
						hidePromoBar={!promoBarVisible}
						showTimer={showCountdown}
						region={props.region}
						saleName={props.saleName}
						path={router.asPath}
						setClosed={promoBarVisible ? handlePromoBarClosed : () => setPromoBarClosed(closed)}
					/>

					<div
						className={`${styles.desktop_nav_container} ${mounted ? styles.mounted : ''}`}
						style={{ height: '70px', gridTemplateColumns: `56px 1fr ${new Array(numberOfCTAItems).fill('auto').join(' ')}` }}
					>
						<Img
							className={styles.logo}
							src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
							alt={'Eight Sleep Logo'}
							// href={`/${props.region}`}
							href={`/`}
							id={'8s-logo-desktop'}
						/>
						<div className={styles.desktop_items_wrapper}>
							<ul className={styles.desktop_items}>
								{menus.map((menu) => {
									return (
										<li
											key={menu.title}
											className={menu.excludeRegion?.includes(props.region) || hideNavLinks ? styles.hide : ''}
										>
											<NavBarItem {...menu} />
										</li>
									)
								})}
							</ul>
						</div>
						<div style={{ display: !props.renderCountryPicker ? 'none' : '' }}>
							<CountryPicker
								region={props.region}
								flipHorizontal
								clickCountry={handleSetRegion}
								onClick={props.onPickerClick}
							/>
						</div>
						<LoadingSkeleton
							width={'1.5rem'}
							height={'1.5rem'}
						>
							{renderCTA(_transparent)}
							{getCart(ctaVisible, props.numberOfCartItems, props.cartClick)}
							{/*{loginButton()}*/}
						</LoadingSkeleton>
					</div>

					{/* Mobile Lower Nav Component */}
					<div className={styles.mobile_nav_container}>
						<div className={styles.mobile_upper}>
							<Button
								unstyled
								className={styles.hamburger}
								onClick={openMobileNav}
								content={
									<Img
										src={'https://eightsleep.imgix.net/hamburger_mobile.svg?v=1622155699'}
										alt={'Hamburger Icon'}
									/>
								}
							/>
							<Img
								className={styles.logo}
								src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
								alt={'Eight Sleep Logo'}
								href={'/'}
								id={'8s-logo-mobile'}
							/>
							<div className={styles.mobile_spacer} />
							<div style={{ display: !props.renderCountryPicker ? 'none' : '' }}>
								<CountryPicker
									region={props.region}
									flipHorizontal
									clickCountry={handleSetRegion}
									onClick={props.onPickerClick}
								/>
							</div>
							<NewButton.Empty
								id={'nav-zowie'}
								onClick={() => {
									const kustomerIframe = document.getElementById('kustomer-ui-sdk-iframe')
									if (kustomerIframe) {
										kustomerIframe.style.visibility = 'visible'
										kustomerIframe.style.display = 'unset'
										window.Kustomer?.open()
									}

									if (window.duet && window.duet.isReady()) {
										window.duet.open()
									}
								}}
								className={styles.zowie}
							>
								<Icon icon={chat} />
							</NewButton.Empty>

							{renderCTA(_transparent)}
							{props.renderCountryPicker ? null : getCart(ctaVisible, props.numberOfCartItems, props.cartClick)}
							{/*{loginButton()}*/}
						</div>
						{!hideMobileNavLinks && (
							<ul className={`${styles.mobile_lower} ${props.isVanity ? styles.reduced_list : ''} ${mounted ? styles.mounted : ''}`}>
								{subNavMenus.map((menu) => {
									if (menu.excludeRegion?.includes(props.region)) return null
									return (
										<li key={menu.title}>
											<NavBarItem {...menu} />
										</li>
									)
								})}
							</ul>
						)}
					</div>
				</div>

				<MobileNav
					useFocusTrap={true}
					open={mobileNavOpen}
					closeNav={closeMobileNav}
					region={props.region}
					setRegion={handleSetRegion}
					ctaVisible={ctaVisible}
					onCountryPickerClick={props.onPickerClick}
					cartIcon={props.renderCountryPicker}
					cartClick={props.cartClick}
					numberOfCartItems={props.numberOfCartItems}
				/>

				<div className={cx(styles.shade_closed, { [styles.shade_open]: mobileNavOpen })} />
			</header>
			{!transparencyAllowed && !floatingNav && (
				<div
					id={'header-spacer'}
					className={`${styles.spacer} ${hideSpacerDesktop && styles.hide_spacer_desktop} ${hideMobileNavLinks ? styles.without_subnav : ''} ${hideSpacerMobile ? styles.hide_spacer_mobile : ''} ${
						promoBarClosed ? styles.promobar_hidden : ''
					}`}
				/>
			)}
		</>
	)
}

function getMenus() {
	const reviews: NavBarItemProps = {
		title: 'Reviews',
		href: '/wall-of-love',
		tracking: () => trackClickNavLink('Main', 'Reviews'),
	}

	const exploreCover: NavBarItemProps = {
		title: 'How it works',
		href: '/pod-cover',
		tracking: () => trackClickNavLink('Main', 'Pod Cover'),
	}

	const intelligence: NavBarItemProps = {
		title: 'Autopilot',
		href: '/autopilot',
		tracking: () => trackClickNavLink('Main', 'App'),
	}

	const science: NavBarItemProps = {
		title: 'Science',
		href: '/science',
		tracking: () => trackClickNavLink('Main', 'Science'),
	}

	const accessories: NavBarItemProps = {
		title: 'Accessories',
		href: '/accessories',
		tracking: () => trackClickNavLink('Main', 'Accessories'),
		excludeRegion: ['ca', 'eu', 'uk', 'au', 'se', 'dk', 'ae', 'ch', 'mx'],
	}

	const leftSideMenus: NavBarItemProps[] = [exploreCover, intelligence]
	const rightSideMenus: NavBarItemProps[] = [science, reviews, accessories]
	const subNavMenus: NavBarItemProps[] = [exploreCover, intelligence, reviews, science, accessories]
	return { leftSideMenus, rightSideMenus, subNavMenus }
}

function getBlogMenus() {
	const cat0 = {
		title: 'Blog Home',
		href: '/blog/',
		//tracking:
	}
	const cat1 = {
		title: 'Sleep',
		href: '/blog/sleep/',
		//tracking:
	}
	const cat2 = {
		title: 'Wellness',
		href: '/blog/wellness/',
		//tracking:
	}
	const cat3 = {
		title: 'Fitness',
		href: '/blog/fitness/',
		//tracking:
	}
	const cat4 = {
		title: 'Members',
		href: '/blog/members/',
		//tracking:
	}
	const cat5 = {
		title: 'Science',
		href: '/blog/science/',
		//tracking:
	}

	const leftSideMenus: NavBarItemProps[] = []
	const rightSideMenus: NavBarItemProps[] = [cat0, cat1, cat2, cat3, cat5]
	const subNavMenus: NavBarItemProps[] = [cat0, cat1, cat2, cat3, cat5]
	return { leftSideMenus, rightSideMenus, subNavMenus }
}

function AccessibilityLinks() {
	return (
		<>
			<A
				className="skip-main"
				href="#main"
				role={'link'}
				aria-label="Anchor link to Main Content"
				id={'accessible-link-to-main'}
			>
				Main Content
			</A>

			<A
				className="skip-main"
				href="#footer"
				role={'link'}
				aria-label="Anchor link to Footer"
				id={'accessible-link-to-footer'}
			>
				Footer
			</A>

			<A
				className="skip-main"
				href="/accessibility"
				aria-label="Link to Accessibility"
				role={'link'}
				id={'accessible-link-to-accessibility'}
			>
				Accessibility Statement
			</A>
		</>
	)
}
const isPathInExcludedPages = (path: string, urls: string[], exactMatch = false) => {
	if (path.charAt(path.length - 1) === '/') {
		path = path.slice(0, -1)
	}

	for (const url of urls) {
		if (url === '/blog') {
			// exact match
			if (path === url) {
				return true
			}
		} else if (path.startsWith(url) && !exactMatch) {
			return true
		} else if (path === url) {
			return true
		}
	}

	return false
}

const shouldHideDesktopSpacer = (normalizedPath: string) => {
	for (const url of promoBarExcludedPages) {
		if (normalizedPath.includes(url)) {
			return true
		}
	}

	return false
}

const loginButton = () => {
	const isClientSide = typeof window !== 'undefined'
	const isLoggedIn = isClientSide && (localStorage.getItem('tokenData') || localStorage.getItem('authToken'))

	return (
		<ClientOnly>
			<Button2023.Empty
				className={isLoggedIn ? 'vh' : styles.login_button}
				href={'/login'}
				id={'nav-login-btn'}
				ariaLabel={'Login'}
				size={'compact'}
			>
				<PhantomIcon
					name="UserLight"
					color={'white'}
					size={'32px'}
				/>
			</Button2023.Empty>
		</ClientOnly>
	)
}
