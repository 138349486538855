import { GLOBAL_SALE_DISCOUNT_CODE } from 'stripe_lib/discounts'
import { SiteSettings } from './types'
import { utcStringToUnix } from 'utils/formatters'
import { RegionShort } from 'utils/internationalization'

const GlobalSale: SiteSettings = {
	saleName: "Valentine's Day Sale",
	promoCode: GLOBAL_SALE_DISCOUNT_CODE.toLowerCase(),
	promoBar: {
		'/athletes': {
			message: 'Try the Pod at home for 30 nights',
			link: {
				to: '/product/pod-cover',
			},
		},
		'*': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: {
				to: '/product/pod-cover',
			},
		},
		'/product/pod-cover': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: null,
		},
		'/pod-cover': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: {
				to: '/product/pod-cover',
			},
		},
		'/pod-prospecting': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: null,
		},
		'/pod-prospecting-yt': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: null,
		},
		'/notboring-prospecting': {
			message: "Valentine's Day Sale: Get up to ### off",
			link: null,
		},
		'/pod4-member': {
			message: "Member Valentine's Day Sale: Get up to ### off", // [sic] This is correct, we"re using ### for the pod4-member discount because we want it to be $600 off
			link: null,
		},
		'/member-accessories': {
			message: "Member Valentine's Day Sale: Members save ### off upgrades",
			link: null,
		},
		'/product/sleep-elixir': {
			message: 'Discover calm before bedtime with Sleep Elixir',
			link: null,
		},
	},
	homeHeroTitle: 'Sleep to power a<br />whole new you',
	homeHeroSubtitle: "Valentine's Day Sale",
	homeHeroButton: 'Shop now',
	bedPerfectedText: 'The secret to cooler,<br/>more restful sleep',
	shopCardTag: 'Get %%% off when you bundle',
	saleShopSavingsTag: 'Build a bundle, save up to %%% off Pod 4 Ultra',
	stickyCardTag: 'Limited time offer. Ends soon.',
	stickyCardTagMobile: 'Limited time offer. Ends soon.',
	shopCardHeading: <>The resolution you’ll love sticking to</>,
	pod3herotitle: 'The most powerful Pod yet',
	pod3herosubtitle: 'Turning everyday mattresses into cooling machines',
	coverlpherotitle: 'Pod Cover',
	coverlpherosubtitle: "Valentine's Day Sale",
	coverLPButton: 'Shop now',
	compareHeading: 'Kickstart the year fully charged',
	mattresslpherotitle: 'The most refreshing sleep',
	mattresslpherosubtitle: 'Pod Mattress',
	mattressLPButton: 'Shop now',
	comparepagetitle: 'Cooler sleep both ways',
	memberaccessoriestitle: 'Members exclusive shop',
	memberaccessoriessubtitle: 'To thank you for being part of the Eight Sleep community, enjoy Members-only offers.',
	memberaccessoriestagtitle: "Members Valentine's Day Sale",
	savingsTag: '',
	stickybartext: 'Limited time offer',
	hooxsubheading: "Valentine's Day Sale",
	hooxHeading: 'Start sleeping better today',
	appPageHeading: 'Better sleep,<br/> that gets smarter<br/>  every day',
	appPageSuperheading: 'Membership',
	podProUpgradeShopPromoPill: '45% OFF',
	podProUpgradeAccessoryCardSaveText: 'SAVE 40%',
	cartLegalese: "Valentine's Day Sale ends February 14th, 2025 11:59pm PST.",
	checkoutLegalese: "Valentine's Day Sale ends February 14th, 2025 11:59pm PST.",
	startDate: '2025-02-01T07:59:00Z',
	endDate: '2025-02-15T07:59:00Z', // 2:59am EST MAKE SURE TO SET TO DAY AFTER
	activeTimer: false,
}

const MXNLaunchSale: SiteSettings = {
	saleName: 'Launch Week Special',
	promoCode: GLOBAL_SALE_DISCOUNT_CODE.toLowerCase(),
	promoBar: {
		'*': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: {
				to: '/product/pod-cover',
			},
		},
		'/product/pod-cover': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: null,
		},
		'/pod-cover': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: {
				to: '/product/pod-cover',
			},
		},
		'/pod-prospecting': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: null,
		},
		'/pod-prospecting-yt': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: null,
		},
		'/notboring-prospecting': {
			message: 'Launch Week Special: Get ### off Pod 4 Ultra',
			link: null,
		},
		'/pod4-member': {
			message: 'Member Launch Week Special: Get up to ### off', // [sic] This is correct, we're using ### for the pod4-member discount because we want it to be $600 off
			link: null,
		},
		'/member-accessories': {
			message: 'Member Launch Week Special: Members save ### off upgrades',
			link: null,
		},
		'/product/sleep-elixir': {
			message: 'Discover calm before bedtime with Sleep Elixir',
			link: null,
		},
	},
	homeHeroTitle: 'Sleep to power a<br />whole new you',
	homeHeroSubtitle: 'Launch Week Special',
	homeHeroButton: 'Shop now',
	bedPerfectedText: 'The secret to cooler,<br/>more restful sleep',
	shopCardTag: 'Get %%% off when you bundle',
	saleShopSavingsTag: 'Build a bundle, save up to %%% off Pod 4 Ultra',
	stickyCardTag: 'Limited time offer. Ends soon.',
	stickyCardTagMobile: 'Limited time offer. Ends soon.',
	shopCardHeading: <>The resolution you’ll love sticking to</>,
	pod3herotitle: 'The most powerful Pod yet',
	pod3herosubtitle: 'Turning everyday mattresses into cooling machines',
	coverlpherotitle: 'Pod Cover',
	coverlpherosubtitle: 'Launch Week Special',
	coverLPButton: 'Shop now',
	compareHeading: 'Kickstart the year fully charged',
	mattresslpherotitle: 'The most refreshing sleep',
	mattresslpherosubtitle: 'Pod Mattress',
	mattressLPButton: 'Shop now',
	comparepagetitle: 'Cooler sleep both ways',
	memberaccessoriestitle: 'Members exclusive shop',
	memberaccessoriessubtitle: 'To thank you for being part of the Eight Sleep community, enjoy Members-only offers.',
	memberaccessoriestagtitle: 'Members Launch Week Special',
	savingsTag: '',
	stickybartext: 'Limited time offer',
	hooxsubheading: 'Launch Week Special',
	hooxHeading: 'Start sleeping better today',
	appPageHeading: 'Better sleep,<br/> that gets smarter<br/>  every day',
	appPageSuperheading: 'Membership',
	podProUpgradeShopPromoPill: '45% OFF',
	podProUpgradeAccessoryCardSaveText: 'SAVE 40%',
	cartLegalese: 'Launch Week Special ends February 9th, 2025 11:59pm PST.',
	checkoutLegalese: 'Launch Week Special ends February 9th, 2025 11:59pm PST.',
	startDate: '2025-02-10T07:59:00Z',
	endDate: '2025-02-18T07:59:00Z', // 2:59am EST MAKE SURE TO SET TO DAY AFTER
	activeTimer: false,
}

const promos: SiteSettings[] = [GlobalSale]
const ca_promos: SiteSettings[] = []
const au_promos: SiteSettings[] = []
const ae_promos: SiteSettings[] = []
const ch_promos: SiteSettings[] = []
const other_promos: SiteSettings[] = []
const mx_promos: SiteSettings[] = [MXNLaunchSale]
export default promos

export const findPromoMatch = (promo_list: SiteSettings[]): SiteSettings => {
	const nowUnix = Date.now()

	let activePromo: SiteSettings = null
	for (const promo of promo_list) {
		const promoUnixStart = utcStringToUnix(promo.startDate)
		const promoUnixEnd = utcStringToUnix(promo.endDate)

		if (promoUnixStart && promoUnixEnd && nowUnix > promoUnixStart && nowUnix <= promoUnixEnd) {
			activePromo = promo
			break
		}
	}

	return activePromo ?? promo_list[0]
}

export const getActiveSitePromo = (region: RegionShort = ''): SiteSettings => {
	const nowUnix = Date.now()

	let activePromo: SiteSettings = findPromoMatch(promos)

	if (region === 'au' && au_promos.length > 0) activePromo = { ...findPromoMatch(au_promos) }
	if (region === 'ca' && ca_promos.length > 0) activePromo = { ...findPromoMatch(ca_promos) }
	if (region === 'ae' && ae_promos.length > 0) activePromo = { ...findPromoMatch(ae_promos) }
	if (region === 'ch' && ch_promos.length > 0) activePromo = { ...findPromoMatch(ch_promos) }
	if (region === 'mx' && mx_promos.length > 0) activePromo = { ...findPromoMatch(mx_promos) }
	if ((region === 'eu' || region === 'uk' || region === 'se' || region === 'dk') && other_promos.length > 0) activePromo = { ...findPromoMatch(other_promos) }

	if (activePromo.activeTimer && activePromo.endDate) {
		var d = new Date(utcStringToUnix(activePromo.endDate))
		if (d.getTime() < nowUnix) {
			activePromo.activeTimer = false
		} else {
			const lastDays = activePromo.countdownDays || 2
			d.setDate(d.getDate() - lastDays)
			activePromo.activeTimer = nowUnix >= d.getTime()
		}
	}

	return activePromo
}
