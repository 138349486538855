export interface VideoData {
	videoUrl: string
	expertName: string
	expertTitle: string
	posterUrl: string
}

export interface UGCVideoWallProps {
	videos?: VideoData[]
}

export const DEFAULT_VIDEOS: VideoData[] = [
	{
		videoUrl: 'https://d115sb6i1ixllw.cloudfront.net/assets/abby-ugc-cropped.mp4',
		expertName: 'Abby Sharp',
		expertTitle: 'Registered Dietitian & Recipe Writer',
		posterUrl: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/abby-sharp-9-16-poster.png',
	},
	{
		videoUrl: 'https://d115sb6i1ixllw.cloudfront.net/assets/andrew-zheng-ugc-cropped.mp4',
		expertName: 'Andrew Zheng',
		expertTitle: 'Lifestyle Blogger',
		posterUrl: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/andrew-zheng-9-16-poster.png',
	},
	{
		videoUrl: 'https://d115sb6i1ixllw.cloudfront.net/assets/julia-morgan-ugc-cropped.mp4',
		expertName: 'Julia Morgan',
		expertTitle: 'Sports Chiropractor & Fitness Coach',
		posterUrl: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/julia-morgan-9-16-poster.png',
	},
	{
		videoUrl: 'https://d115sb6i1ixllw.cloudfront.net/assets/colin-furze-ugc-cropped.mp4',
		expertName: 'Colin Furze',
		expertTitle: 'Engineer & Inventor',
		posterUrl: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/colin-furze-9-16-poster.png',
	},
]
