import { FC } from 'react'
import styles from './TweetMarquee.module.scss'
import { TweetMarqueeProps, DEFAULT_TWEETS } from './TweetMarquee.types'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'
import Marquee from 'react-fast-marquee'
import { TweetCard } from 'components/WallOfLove/TweetCard'
import { ugcAB } from 'ab_testing'
import useABTestVariant from 'hooks/useABTestVariant'

export const TweetMarquee: FC<TweetMarqueeProps> = (props) => {
	const {
		header = 'Discover how members transformed their sleep with the Pod',
		cta = {
			href: '/wall-of-love',
			text: 'View more reviews',
		},
		tweets = DEFAULT_TWEETS,
	} = props

	const isUGCAB = useABTestVariant(ugcAB, 1)

	if (!isUGCAB) {
		return null
	}

	return (
		<section
			className={styles.container}
			id="tweet-marquee"
		>
			<header className={styles.header}>
				<Type.Headline2>{header}</Type.Headline2>
				{cta && (
					<Button.Dark
						id="tweet-marquee-cta"
						href={cta.href}
						onClick={() => {
							localStorage.setItem('wallOfLoveFilter', 'Everyday love')
						}}
					>
						{cta.text}
					</Button.Dark>
				)}
			</header>

			<div className={styles.marquee}>
				<Marquee
					pauseOnHover
					gradient
					gradientWidth={'10vw'}
					gradientColor="#f9f8f7"
					className={styles.marquee_element}
				>
					{tweets.map((tweet) => (
						<div
							key={tweet.url}
							className={styles.marquee_item}
						>
							<TweetCard
								{...tweet}
								id={tweet.url}
								hideImage
							/>
						</div>
					))}
				</Marquee>
			</div>
		</section>
	)
}
