import { SITE_DOMAIN } from 'constants/index'
import { addEventToLocalStorage, getAllEventsFromLocalStorage } from 'utils/events'
import { formatURL } from 'utils/formatters'
import { CartLineItem } from 'cart/types'
import Stripe from 'stripe'
import { stripePodCoverIds } from 'products/utils'
import { TAX_PRODUCT } from 'stripe_lib'
import { datadogReportEvent } from 'events/datadog'
import { PaymentMethodID } from 'components/Checkout2023/types'
import { getProductCategory } from 'products/productPages'

const KLAVIYO_EVENT_STORE = 'klaviyoEvents'

declare global {
	interface Window {
		_learnq?: any
		klaviyo?: any
	}
}

enum KlaviyoEventType {
	IDENTIFY = 'identify',
	TRACK = 'track',
}

export const sendQueuedKlaviyoEvents = async (email: string) => {
	const utmParamMap = {
		utm_source: 'utm_source_subscribe_session',
		utm_medium: 'utm_medium_subscribe_session',
		utm_campaign: 'utm_campaign_subscribe_session',
		utm_content: 'utm_content_subscribe_session',
		utm_term: 'utm_term_subscribe_session',
	}
	const userUtms = {}

	if (typeof window === 'undefined') {
		return
	}

	try {
		// identify the user by email
		window.klaviyo.unshift(['identify', { $email: email }])
		// parse utms from current session storage
		for (const utm of Object.keys(utmParamMap)) {
			const utmValue = sessionStorage.getItem(utm)
			if (utmValue && utmValue.length > 0) {
				const klaviyoProfileProperty = utmParamMap[utm]
				userUtms[klaviyoProfileProperty] = utmValue
				sessionStorage.removeItem(utm)
			}
		}

		// if they have utms from current session, send to klaviyo
		if (Object.entries(userUtms).length > 0) {
			window.klaviyo.push(['identify', userUtms])
		}

		// send queued events from localstorage
		const queuedEvents = getAllEventsFromLocalStorage('klaviyoEvents')
		if (queuedEvents) {
			await queuedEvents.forEach((event) => {
				window.klaviyo.push(event)
			})
			// remove the store once the events are sent
			localStorage.removeItem(KLAVIYO_EVENT_STORE)
		}
		datadogReportEvent('success', 'Klaviyo - Queued Events', queuedEvents)
	} catch (e) {
		console.log(e)
		datadogReportEvent('failure', 'Klaviyo - Queued Events', e)
	}
}

export const klaviyoTrackLatestCart = (cartString: string) => {
	klaviyoSetUserProperties({ last_cart: cartString })
}

export const klaviyoTrackQuizAnswers = (quizData: any) => {
	try {
		const email = localStorage.getItem('email')
		klaviyoEvent(KlaviyoEventType.TRACK, 'Ecap Quiz', quizData)
		window.klaviyo.push(['identify', { $email: email, ...quizData }])
	} catch (e) {
		console.log(e)
	}
}

const klaviyoSetUserProperties = (userProperties: any) => {
	klaviyoEvent(KlaviyoEventType.IDENTIFY, null, userProperties)
}

const klaviyoEvent = (eventType: KlaviyoEventType, eventName, eventProperties: any = null) => {
	if (typeof window === 'undefined') {
		return
	}
	let event = null

	if (eventType === KlaviyoEventType.TRACK) {
		event = eventProperties ? [eventType, eventName, eventProperties] : [eventType, eventName]
	} else if (eventType === KlaviyoEventType.IDENTIFY) {
		event = [eventType, eventProperties]
	} else {
		console.log('Error: Missing klaviyo event type')
		return
	}

	// if the user has an email, identify the user and send to klaivyo; otherwise, save in localStorage
	try {
		const email = localStorage.getItem('email')
		if (!email) {
			addEventToLocalStorage(KLAVIYO_EVENT_STORE, event)
		} else {
			window.klaviyo.push(['identify', { $email: email }])
			window.klaviyo.push(event)
		}
		datadogReportEvent('success', eventName || 'identify', event)
	} catch (e) {
		console.log(e)
		datadogReportEvent('failure', eventName || 'identify', event)
	}
}

export const kyTriggerCartAbandonedFlowHandler = () => {
	const email = localStorage.getItem('email')
	const cartId = sessionStorage.getItem('klaviyo_cart_id')
	if (email && cartId) {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Trigger Cart Abandoned Flow')
	}
}

export const kyEmailAvailableHandler = async (email, location) => {
	await sendQueuedKlaviyoEvents(email)
	kyTriggerCartAbandonedFlowHandler()
}

export const kyClickedFAQ = (question) => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Click - FAQ - The Pod', { Question: question })
}

export const kyVistedReturnPolicyPage = () => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /return-policy/')
}

export const kyVistedWarrantyPage = () => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /warranty/')
}

export const kyVistedReviewsPage = () => {
	klaviyoEvent(KlaviyoEventType.TRACK, 'Visited - /reviews/')
}

// btoa(unescape(encodeURIComponent(str)))
export const kyTrackShippingAddress = (addressObj) => {
	const userProperties = {
		s_firstName: btoa(addressObj['firstName']),
		first_name: addressObj['firstName'],
		s_lastName: btoa(addressObj['lastName']),
		s_address1: btoa(addressObj['address1']),
		s_address2: btoa(addressObj['address2']),
		s_city: btoa(addressObj['city']),
		s_province: btoa(addressObj['province']),
		s_country: btoa(addressObj['country']),
		s_phone: btoa(addressObj['phone']),
		s_zip: btoa(addressObj['zip']),
	}
	klaviyoSetUserProperties(userProperties)
}

export const kyViewedMenopauseCampaign = () => {
	const userProperties = {
		menopauseCampaignVisitor: true,
	}
	klaviyoEvent(KlaviyoEventType.IDENTIFY, null, userProperties)
	klaviyoEvent(KlaviyoEventType.TRACK, 'Trigger Menopause Flow')
}

export const kyAddToCart = (item: CartLineItem) => {
	const _item = {
		...item,
		price: (Math.round(item.price) / 100).toFixed(2),
		line_price: (Math.round(item.price) / 100).toFixed(2),
		product_url: SITE_DOMAIN + window.location.pathname,
	}

	klaviyoEvent(KlaviyoEventType.TRACK, 'Added Item to Cart', { Item: _item })
}

export function kyPageView() {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Page View', { URL: formatURL(window.location.href) })
	} catch (e) {
		console.log(e)
	}
}

export function emailCapShown() {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Blog Ecap View', { URL: formatURL(window.location.href) })
	} catch (e) {
		console.log(e)
	}
}

export function trackT1EcapSignUp(email: string, quote: string, image: string, name: string, code: string, link: string) {
	try {
		const event = { quote, image, name, code, link }
		klaviyoEvent(KlaviyoEventType.TRACK, 'Vanity Ecap Signup', event)
		window.klaviyo.push(['identify', { $email: email }])
	} catch (e) {
		console.log(e)
	}
}

export function klaviyoTrackLatestStripeCart(email, invoice: Stripe.UpcomingInvoice, currency: string, paymentMethod: PaymentMethodID) {
	try {
		const cartString = invoice.lines.data.map((li) => `${li.price.id}:${li.quantity}`).join(',')
		const truemedSelected = paymentMethod === 'truemed'
		const financingSelected = paymentMethod === 'affirm' || paymentMethod === 'klarna'
		const isMembershipName = (s: string) => {
			const n = s.toLowerCase()
			return n.includes('membership') || n.includes('autopilot')
		}

		const membership = invoice.lines.data.find((li) => li.plan!) || invoice.lines.data.find((li) => isMembershipName(li.description))

		let membershipType = 'none'
		if (membership) {
			membershipType = membership.description.toLocaleLowerCase().includes('enhanced') ? 'enhanced' : 'standard'
		}

		const checkoutData = {
			truemedSelected: truemedSelected,
			financingSelected: financingSelected,
			membershipType: membershipType,
			totalCartValue: invoice.total,
			checkoutUrl: `https://www.eightsleep.com/cart2?items=${cartString}`,
			stripe_cart: cartString,
			currency: currency?.toLowerCase(),
			products: invoice.lines.data.map((item) => {
				return {
					id: (item.price.product as Stripe.Product).id,
					name: item.description,
					size: item.price.nickname,
					quantity: item.quantity,
					imageUrl: (item.price.product as Stripe.Product).images[0],
					category: getProductCategory(item.description),
				}
			}),
		}

		klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Viewed', checkoutData)
		window.klaviyo.push(['identify', { $email: email }])
	} catch (e) {
		console.log(e)
	}
}

export function klaviyoTrackStripeCheckoutInit() {
	try {
		if (typeof window !== 'undefined' && localStorage.getItem('email')) {
			klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Initialized')
			window.klaviyo.push(['identify', { $email: localStorage.getItem('email') }])
		}
	} catch (e) {
		console.log(e)
	}
}

export function klaviyoTrackStripePurchaseComplete(email: string, invoiceId: string) {
	try {
		klaviyoEvent(KlaviyoEventType.TRACK, 'Stripe Checkout - Completed')
		window.klaviyo.push(['identify', { $email: email, invoiceId: invoiceId }])
	} catch (e) {
		console.log(e)
	}
}

const invoiceItemsToRestResponse = (items: Stripe.ApiList<Stripe.InvoiceLineItem>) => {
	return items.data.map((item) => {
		return {
			id: item.id,
			amont: item.amount,
			amount_excluding_tax: item.amount_excluding_tax,
			currency: item.currency,
			description: item.description,
			discount_amounts: item.discount_amounts,
			discounts: item.discounts,
			plan: item.plan
				? {
						id: item.plan.id,
						amount: item.plan.amount,
						billing_scheme: item.plan.billing_scheme,
						currency: item.plan.currency,
						interval: item.plan.interval,
						interval_count: item.plan.interval_count,
						product: item.plan.product,
				  }
				: null,
			lookup_key: item.price.lookup_key,
			price_metadata: item.price.metadata,
			nickname: item.price.nickname,
			product_id: (item.price.product as Stripe.Product).id,
			price_id: item.price.id,
			product_attributes: (item.price.product as any).attributes,
			images: (item.price.product as Stripe.Product).images,
			product_metadata: (item.price.product as Stripe.Product).metadata,
			unit_amount: item.price.unit_amount,
			quantity: item.quantity,
			tax_amounts: item.tax_amounts,
			tax_rates: item.tax_rates,
		}
	})
}

export const invoiceToRestResponse = (invoice: Stripe.Invoice | Stripe.UpcomingInvoice) => {
	const items = invoiceItemsToRestResponse(invoice.lines)
	const pod = items.find((item) => stripePodCoverIds.includes(item.price_id))
	let podTimeline = ''

	if (pod) {
		if ('shippingTimeline2' in invoice.metadata) {
			const json = JSON.parse(invoice.metadata.shippingTimeline2)
			if (json) {
				const t = Object.values(json)[0]
				podTimeline = pod.description + ' ' + t
			}
		}
	}

	podTimeline = podTimeline.replace('Ships', 'ships by')

	const result = {
		id: 'id' in invoice ? invoice.id : null,
		order_number: invoice.number,
		pod_timeline: podTimeline,
		order_confirmation_url: 'id' in invoice ? 'https://www.eightsleep.com/thank_you/' + invoice.id : '',
		amount_due: invoice.amount_due / 100,
		amount_shipping: invoice.amount_shipping / 100,
		created: invoice.created,
		currency: invoice.currency,
		custom_fields: invoice.custom_fields,
		customer_address: invoice.customer_address,
		customer_email: invoice.customer_email,
		customer_name: invoice.customer_name,
		customer_phone: invoice.customer_phone,
		customer_shipping: invoice.customer_shipping,
		customer_billing: ((invoice.payment_intent as Stripe.PaymentIntent)?.latest_charge as Stripe.Charge)?.billing_details,
		discounts: invoice.discounts,
		metadata: invoice.metadata,
		items: items.map((item) => {
			const i = { ...item, amont: item.amont / 100, amount_excluding_tax: item.amount_excluding_tax / 100, unit_amount: item.unit_amount / 100 }
			const priceId = item.price_id
			const metaKey = `${priceId}_timeline`
			if (invoice && metaKey in invoice.metadata) {
				i.shippingTimeline = invoice.metadata[metaKey]
			}
			return i
		}),
		customer: (invoice.customer as Stripe.Customer)?.id,
		client_secret: (invoice.payment_intent as Stripe.PaymentIntent)?.client_secret,
		shipping_cost: invoice.shipping_cost,
		shipping_details: invoice.shipping_details,
		status: invoice.status,
		subscription: invoice.subscription,
		subscription_details: invoice.subscription_details,
		subtotal: invoice.subtotal / 100,
		subtotal_excluding_tax: invoice.subtotal_excluding_tax / 100,
		total: invoice.total / 100,
		total_discount_amounts: invoice.total_discount_amounts,
		total_discount: invoice.total_discount_amounts?.reduce((acc, curr) => acc + curr.amount, 0) / 100,
		total_excluding_tax: invoice.total_excluding_tax / 100,
		total_tax_amounts: invoice.total_tax_amounts,
		replacement: 'replacement' in invoice.metadata ? invoice.metadata.replacement === 'true' : false,
	}

	const taxItem = invoice.lines.data.find((it) => it.price.product === TAX_PRODUCT)
	if (taxItem && taxItem.amount > 0) {
		result.tax = taxItem.amount / 100
	}

	return result
}
