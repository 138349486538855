export const VARIABLE_CURRENCY_MAP = {
	SEK: {
		25: 350,
		50: 700,
		75: 1000,
		100: 1400,
		125: 1750,
		150: 2100,
		200: 2800,
		250: 3500,
		275: 3800,
		300: 4200,
		350: 4900,
		400: 5600,
		450: 6300,
		500: 7000,
		550: 7700,
		600: 8400,
		650: 9100,
		700: 9800,
		5300: 74200, // Special case for random coupons requested by Rafa. If you still see this past March 2025, feel free to delete it.
	},
	DKK: {
		25: 350,
		50: 475,
		75: 700,
		100: 950,
		125: 1150,
		150: 1425,
		200: 1900,
		250: 2375,
		275: 2500,
		300: 2700,
		350: 3100,
		400: 3800,
		450: 4400,
		500: 4750,
		550: 5225,
		600: 5700,
		650: 6500,
		700: 7100,
		5300: 53800, // Special case for random coupons requested by Rafa. If you still see this past March 2025, feel free to delete it.
	},
	AED: {
		25: 100,
		50: 200,
		75: 300,
		100: 400,
		125: 500,
		150: 550,
		200: 800,
		250: 1000,
		275: 1100,
		300: 1200,
		350: 1300,
		400: 1500,
		450: 1700,
		500: 2000,
		550: 2200,
		600: 2400,
		650: 2500,
		700: 2700,
		5300: 19500, // Special case for random coupons requested by Rafa. If you still see this past March 2025, feel free to delete it.
	},
	MXN: {
		25: 600,
		50: 1200,
		75: 1800,
		100: 2400,
		125: 3000,
		150: 3600,
		200: 4800,
		250: 6000,
		275: 6600,
		300: 7200,
		350: 8400,
		400: 9600,
		450: 10800,
		500: 12000,
		550: 13200,
		600: 14400,
		650: 16200,
		700: 18000,
		5300: 97000, // Special case for random coupons requested by Rafa. If you still see this past March 2025, feel free to delete it.
	},
}

export const getRandomString = (length = 15) => {
	return [...Array(length)].map((i) => Math.trunc(Math.random() * 36).toString(36)).join('')
}
