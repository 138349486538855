import * as React from 'react'
import { Main as MainLayout } from 'layouts'
import { ShopWrapper } from 'sections/PodAtc/PodAtc'
import { AppMetadata } from 'components/AppMetadata'
import { PodCoverProducts } from 'products/allProducts'
import { rootStore, RootStore } from 'stores/index'
import { Product, ProductCore, ProductShopDataType } from 'products/types'
import { inject, observer } from 'mobx-react'
import { isPodCoverHandle, mapUrlToShop } from 'products/utils'
import { Waypoint } from 'react-waypoint'
import { SKIP_MEMBERSHIP_CHECKOUT_KEY } from 'constants/index'
// import { Shop } from 'components/WebEv/Shop'
import Script from 'next/script'
import { getRegionPod4DefaultKey, getRegionPod4UltraDefaultKey, PriceManager } from 'prices'
import Head from 'next/head'
import { PodUpsell } from 'components/PodUpsell'
import { ConnectedShop } from 'components/Phantom/_pageCompositions/Shop'
import { Pod4ShopComparison } from 'components/Pod4ShopComparison'
import { ShopNewsConnected } from 'components/Pod4ShopNews'
import { Pod4ShopQualityPromises } from 'components/Pod4ShopQualityPromises'
import { Pod4FAQ } from 'components/Pod4FAQ'
import { PodModel } from 'shop/shopManager'
import { BaseExplainerConnected } from 'components/BaseExplainer'
import { PLAN_PRICING, SubscriptionTier } from 'utils/membership'
import AccessoryCTA from 'components/AccessoryCTA/AccessoryCTA'
import { SleepElixirPDP } from './sleep-elixir-pdp'
import { ShopStoreState } from 'stores/shop-store'
import { Pod4FAQProps } from 'components/Pod4FAQ/types'
import { passesFilter } from 'utils/sanity'
import { UGCVideoWall } from 'components/UGCVideoWall'
import { TweetMarquee } from 'components/TweetMarquee'

export interface VariantType {
	id: number
	name: string
	price: number
}

export interface Props {
	handle?: string
	path: string
	product?: ProductCore
	showPrice?: boolean
	showStock?: boolean
	pathName?: string
	defaultProductId?: string
	rootStore?: RootStore
	initialShopStoreState?: ShopStoreState
	faqData?: {
		coverFAQData: Pod4FAQProps
		insertFAQData: Pod4FAQProps
		memberUpgradeFAQData: Pod4FAQProps
	}
}

interface State {
	ContentSection?: any
	sentTracking: boolean
	selectedProduct?: Product
	showStickyBar?: boolean
}

export function parseShopUrl(path: string) {
	let url = path
	if (url.includes('?')) {
		url = url.split('?')[0]
	}
	if (url.includes('#')) {
		url = url.split('#')[0]
	}

	if (url.charAt(url.length - 1) === '/') {
		url = url.substring(0, url.length - 1)
	}
	return url
}

const POD_4_ULTRA_PATHS = ['pod4ultra', 'pod-compare', 'cooling-sleep']
export const getDefaultProductId = (path: string, asPath?: string, experiments?: string): PodModel => {
	if (POD_4_ULTRA_PATHS.some((path) => asPath?.includes(path))) return 'pod_4_ultra'
	return 'pod_4'
}

export const shouldShowShopBanner = (pathIndex?: string, asPath?: string) => {
	if (pathIndex && pathIndex.includes('pod-cover-email')) return true
	if (asPath && asPath.includes('pod-cover-email')) return true
	return false
}

export function getProductProps(productPathname: string): Props {
	const path = parseShopUrl(productPathname)
	const shopPath = mapUrlToShop(path)
	const productData: ProductShopDataType = rootStore.productStore.getShopPageProducts(shopPath)
	const { products, defaultProductSelected } = productData
	const product = products[defaultProductSelected]
	const handle = product.handle
	return { handle, product, path, defaultProductId: getDefaultProductId(shopPath) }
}

const getBaseFAQData = (isMember: boolean, handle: string, faqData: Props['faqData']) => {
	if (handle.includes('pod4-insert')) {
		return faqData?.insertFAQData
	}

	if (isMember) {
		return faqData?.memberUpgradeFAQData
	}

	return faqData?.coverFAQData
}

class ProductCorePageComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			ContentSection: null,
			sentTracking: false,
			showStickyBar: true,
		}
	}

	async componentDidMount() {
		window.dataLayer?.push({ product_id: this.props.product?.id.toString() })
		if (localStorage.getItem('member') === 'true') {
			sessionStorage.setItem(SKIP_MEMBERSHIP_CHECKOUT_KEY, 'true')
		} else {
			sessionStorage.removeItem(SKIP_MEMBERSHIP_CHECKOUT_KEY)
		}
		setTimeout(() => {
			const settingsStore = this.props.rootStore?.settingsStore
			const priceStore = this.props.rootStore?.priceStore
			const path = window.location.pathname
			const product = path.split('/product/')[1].replace(/\//g, '')

			if (path.includes('/product/')) {
				if (path.includes('/product/pod-cover/')) {
					this.props.rootStore.shopStore.trackViewShopPage()
				} else {
					const pagePath = path || (typeof window !== 'undefined' ? window.location.pathname : '')
					const shop = mapUrlToShop(pagePath)
					const shopData = rootStore.productStore.getShopPageProducts(shop)

					const products = shopData.products.map((p) => priceStore.withPrices(p))
					const product = products[shopData.defaultProductSelected]
					const defaultVariant = shopData.dropdownItems[0].defaultVariantSelected

					const price = product.variants[defaultVariant].prices.priceString
					const productPath = window.location.pathname.split('product/')[1].replace(/\//g, '')
					rootStore.shopStore.trackViewAccessoryShop(price, defaultVariant as any, productPath)
				}
			}
		}, 2000)
	}

	async componentDidUpdate(prevProps: Props) {
		if (prevProps.product?.id !== this.props.product?.id) {
			window.dataLayer?.push({ product_id: this.props.product?.id.toString() })
		}
	}

	render() {
		const { handle, path, defaultProductId, initialShopStoreState, faqData } = this.props

		const { ContentSection } = this.state
		const { settingsStore, productStore, priceStore } = this.props.rootStore!

		const pagePath = path || (typeof window !== 'undefined' ? window.location.pathname : '')
		const shop = mapUrlToShop(pagePath)
		const isCoverShopPage = typeof window !== 'undefined' && isPodCoverHandle(handle || '')

		const shopData = productStore.getShopPageProducts(shop)
		const memberShopPage = pagePath.includes('eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208')
		const region = settingsStore.currentRegion
		const currency = priceStore.currency

		const cartLoading = this.props.rootStore?.cartStoreNew.updatingCart // DO NOT delete, this forces a rerender in Shopmain for some reason

		const isMember = rootStore.settingsStore.settings.promoCode === 'members'
		const baseFAQData = getBaseFAQData(isMember, handle || '', faqData)

		const filteredFAQData = baseFAQData?.faq.filter((faq) => passesFilter(faq.filters, region, isMember))

		const StructuredDataSnippet = () => {
			if (!isCoverShopPage) return null

			const productSKU = defaultProductId === 'pod_4' ? '10512-001' : '21500-000'
			const productName = defaultProductId === 'pod_4' ? 'Pod 4' : 'Pod 4 Ultra'

			const product = defaultProductId === 'pod_4' ? PodCoverProducts.Pod4 : PodCoverProducts.Pod4Ultra
			const regionDefaultVariantKey = defaultProductId === 'pod_4' ? getRegionPod4DefaultKey(region) : getRegionPod4UltraDefaultKey(region)
			const defaultVariant = product.variants[regionDefaultVariantKey]?.id
			const priceMap = priceStore.getVariantPrices(defaultVariant || product.variants.queen.id)
			const productPrice = parseFloat(priceMap[currency].price!) + PLAN_PRICING[SubscriptionTier.Plus][currency]
			const regionPrefix = region && region !== 'us' ? region + '/' : ''
			const productURL = `https://www.eightsleep.com/${regionPrefix}${pagePath.replace('/', '')}/`
			const productDescription = PriceManager.getRegionDefaultProductDescription(region, product)
			const productImage =
				defaultProductId === 'pod_4'
					? 'https://cdn.shopify.com/s/files/1/1354/6123/files/google_shopping_image_may_2023.png?v=1684961973'
					: 'https://cdn.shopify.com/s/files/1/1354/6123/files/pod3-summerbedroom-topviewfx-CROP2x.png?v=1715113088'

			return (
				<Script
					type="application/ld+json"
					id={`structured-data-script-${handle}`}
					dangerouslySetInnerHTML={{
						__html: `
							{
								"@context": "https://schema.org/",
								"@type": "Product",
								"name": "${productName}",
								"image": [
									"${productImage}"
								],
								"description": "${productDescription}",
								"sku": "${productSKU}",
								"brand": {
									"@type": "Brand",
									"name": "Eight Sleep"
								},
								"offers": {
									"@type": "Offer",
									"url": "${productURL}",
									"price": ${productPrice},
									"priceCurrency": "${currency}",
									"itemCondition": "https://schema.org/NewCondition",
									"availability": "https://schema.org/InStock"
								}
							}
						`,
					}}
				/>
			)
		}

		if (isPodCoverHandle(handle || '')) {
			return (
				<MainLayout>
					<Head>
						<link
							rel="prefetch"
							href="https://eight-eightsleep.s3.amazonaws.com/cover_falling_bedroom.mp4"
						/>
						<link
							rel="prefetch"
							href="https://eight-eightsleep.s3.amazonaws.com/cover_falling_white.mp4"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/shop_pod3_mattress_size_full.png"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/shop_pod3_mattress_size_queen.png"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/pod3_size_king.jpg"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/pod3_size_caliking.jpg"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/shop_pod4_mattress_size_full.png"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/shop_pod4_mattress_size_queen.png"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/pod4_size_king.jpg"
						/>
						<link
							rel="preload"
							as="image"
							href="https://eightsleep.imgix.net/pod4_size_caliking.jpg"
						/>
					</Head>
					<StructuredDataSnippet />
					<AppMetadata
						path={pagePath}
						key={pagePath}
					/>
					<ConnectedShop />
					<ShopNewsConnected />

					<UGCVideoWall />
					<TweetMarquee />

					<BaseExplainerConnected />
					<Pod4ShopQualityPromises />
					<Pod4ShopComparison ctaHref="#shop" />
					<Pod4FAQ faq={filteredFAQData} />
				</MainLayout>
			)
		}

		if (handle === 'sleep-elixir') {
			return (
				<SleepElixirPDP
					initialShopStoreState={initialShopStoreState}
					pagePath="/product/sleep-elixir/"
				/>
			)
		}

		return (
			<MainLayout>
				<StructuredDataSnippet />
				<AppMetadata
					path={pagePath}
					key={pagePath}
				/>
				<Waypoint
					onEnter={() => this.setState({ showStickyBar: false })}
					onLeave={() => this.setState({ showStickyBar: true })}
				>
					<div>
						<ShopWrapper
							{...shopData}
							path={pagePath}
							rootStore={this.props.rootStore!}
							cartStoreNew={this.props.rootStore?.cartStoreNew}
							settingsStore={this.props.rootStore?.settingsStore}
							priceStore={this.props.rootStore?.priceStore}
							key={pagePath}
							currentModal={this.props.rootStore?.shopStore?.activeModal}
						/>
					</div>
				</Waypoint>
				<AccessoryCTA
					handle={handle}
					rootStore={this.props.rootStore}
				/>
				{pagePath.includes('pod4-insert') && <Pod4FAQ faq={filteredFAQData} />}
				<PodUpsell />
			</MainLayout>
		)
	}
}

export const ProductCorePage = inject('rootStore')(observer(ProductCorePageComponent))
