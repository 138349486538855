import React, { FC } from 'react'
import styles from './PodUpsell.module.scss'
import { Img } from 'components/basic/Img/Img'
import { Button } from 'components/Phantom/Button/Button'
import { Type } from 'components/Type'

export const PodUpsell: FC = () => {
	return (
		<div className={styles.container}>
			<Img
				src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pedestal-2025-crop.jpg"
				sources={[
					{
						mediaQuery: '(max-width: 599px)',
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pedestal-mobile.jpg',
					},
				]}
				alt="Banner image"
				className={styles.image}
				objectFit="cover"
			/>

			<div className={styles.image_mask_1} />
			<div className={styles.image_mask_2} />

			<div className={styles.content_container}>
				<header>
					<Type.Headline2 className={styles.title}>Get the Pod</Type.Headline2>
					<Type.Headline2
						as="p"
						className={styles.subtitle}
					>
						Elevate your sleep
						<br />
						experience
					</Type.Headline2>
				</header>

				<Button.White
					id={'shop-card-cta'}
					href="/product/pod-cover"
				>
					Shop now
				</Button.White>
			</div>
		</div>
	)
}
