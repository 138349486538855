import { Slide } from 'components/BaseExplainer/BaseExplainer.types'
import { regionIsMetric, RegionShort } from 'utils/internationalization'

export const getBaseExplainerSlides = (region: RegionShort) => {
	const slides: Slide[] = []

	const isMetric = regionIsMetric(region)

	const baseThickness = isMetric ? '8cm' : '3”'
	const baseWeightCapacity = isMetric ? '340kg' : '750 lbs'

	const legKitMinHeight = isMetric ? '20cm' : '8”'
	const legKitMaxHeight = isMetric ? '33cm' : '13”'

	slides.push({
		media: [
			{
				type: 'video',
				data: {
					src: 'https://d115sb6i1ixllw.cloudfront.net/assets/web_base_no_legs.mp4',
					alt: 'Base explainer no legs',
				},
			},
			{
				type: 'video',
				data: {
					src: 'https://d115sb6i1ixllw.cloudfront.net/assets/web_base_legs.mp4',
					alt: 'Base explainer legs',
				},
			},
		],
		title: 'How to install',
		lists: [
			{
				title: 'Use it with your existing bed frame and mattress',
				items: ['Place it between your current mattress and bed frame', 'Add the Pod Cover to your mattress', 'Connect to the Hub via the Eight Sleep App'],
			},
			{
				title: 'Or use it to replace your bed frame',
				items: [
					'Use the optional leg kit, adding the legs to the Base',
					'Place your mattress right over the Base',
					'Add the Pod Cover to your mattress',
					'Connect to the Hub via the Eight Sleep App ',
				],
			},
		],
	})

	slides.push({
		media: [
			{
				type: 'video',
				data: {
					src: 'https://d115sb6i1ixllw.cloudfront.net/assets/sandwhich_slider_new.mp4',
					alt: 'The Pod 4 Ultra Base',
				},
			},
		],
		title: 'Features & Details',
		lists: [
			{
				title: '',
				items: [
					`Disappears in your bed frame, being only ${baseThickness} tall`,
					`Holds up to ${baseWeightCapacity} and runs on up to 100% battery power`,
					'Moves both sides at the same time, quietly and smoothly',
					'Control head and feet elevation up to 45 degrees for head and 20 degrees for feet',
					'Define pre-sets for automatic adjustments at certain times of the day or night',
					'Automatically elevates to reduce snoring when detected',
				],
			},
		],
	})

	slides.push({
		media: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Base+Explainer+Sharp.png',
					alt: 'The Pod 4 Ultra Base by itself',
				},
			},
		],
		title: 'Dimensions',
		lists: [
			{
				title: 'Base dimensions',
				items: getBaseDimensionsItems(region),
			},
			{
				title: 'Base weight capacity',
				items: getBaseWeightItems(region),
			},
			{
				title: 'Optional leg kit',
				items: [`Optional legs are adjustable between ${legKitMinHeight} and ${legKitMaxHeight} in height`],
			},
		],
	})

	slides.push({
		media: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Compatibility-Desktop-v44.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new_base_mob_3_v2.jpg',
					alt: 'The Pod 4 Ultra Base cross section',
				},
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/base_explainer_with_bed_frame.png',
					alt: 'The Pod 4 Ultra Base with a beige bed frame',
					objectFit: 'contain',
					caption: 'Use it with your existing bed frame and mattress',
				},
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Base+Explainer+Flat.png',
					alt: 'The Pod 4 Ultra Base lying flat',
					caption: 'Use standalone with the leg kit',
				},
			},
		],
		title: 'Compatibility',
		lists: [
			{
				title: 'Compatible with slatted and platform bed frames/foundations',
				items: ['Can be used as it’s own bed frame or your existing bed frame, if optional leg kit is purchased', 'Requires a power outlet near bed frame'],
			},
			{
				title: 'Compatible with any mattress',
				items: [getMattressMessaging(region)],
			},
		],
	})

	return slides
}

const getBaseDimensionsItems = (region: RegionShort) => {
	switch (region) {
		case 'us':
		case '':
			return ['Queen: 60” W x 80” L x 3” H', 'King: 76” W x 80” L x 3” H', 'California King: 72” W x 84” L x 3” H']
		case 'ca':
			return ['CA Queen: 150cm W x 200cm L x 8cm H', 'CA King: 190cm W x 200cm L x 8cm H']
		case 'eu':
			return ['EU Super King: 180cm W x 200cm L x 8cm H', 'EU Emperor: 200cm W x 200cm L x 8cm H']
		case 'uk':
			return ['UK Super King: 180cm W x 200cm L x 8cm H', 'UK Emperor: 200cm W x 200cm L x 8cm H']
		case 'au':
			return ['AU Queen: 150cm W x 200cm L x 8cm H', 'AU King: 180cm W x 200cm L x 8cm H']
		case 'se':
			return ['SE Super King: 180cm W x 200cm L x 8cm H', 'SE Emperor: 200cm W x 200cm L x 8cm H']
		case 'dk':
			return ['DK Super King: 180cm W x 200cm L x 8cm H', 'DK Emperor: 200cm W x 200cm L x 8cm H']
		case 'ae':
			return ['AE King: 180cm W x 200cm L x 8cm H', 'AE Emperor: 200cm W x 200cm L x 8cm H']
		case 'ch':
			return ['CH Super King: 180cm W x 200cm L x 8cm H', 'CH Emperor: 200cm W x 200cm L x 8cm H']
		case 'mx':
			return ['MX Queen: 150cm W x 200cm L x 8cm H', 'MX King: 190cm W x 200cm L x 8cm H']
		default:
			return []
	}
}

const getBaseWeightItems = (region: RegionShort) => {
	switch (region) {
		case 'us':
		case '':
			return ['Queen: 185 lbs', 'King: 195 lbs', 'California King: 195 lbs']
		case 'ca':
			return ['CA Queen: 84 kg', 'CA King: 89 kg']
		case 'eu':
			return ['EU Super King: 84 kg', 'EU Emperor: 89 kg']
		case 'uk':
			return ['UK Super King: 84 kg', 'UK Emperor: 89 kg']
		case 'au':
			return ['AU Queen: 84 kg', 'AU King: 89 kg']
		case 'se':
			return ['SE Super King: 84 kg', 'SE Emperor: 89 kg']
		case 'dk':
			return ['DK Super King: 84 kg', 'DK Emperor: 89 kg']
		case 'ae':
			return ['AE King: 84 kg', 'AE Emperor: 89 kg']
		case 'ch':
			return ['CH Super King: 84 kg', 'CH Emperor: 89 kg']
		case 'mx':
			return ['MX Queen: 84 kg', 'MX King: 89 kg']
		default:
			return []
	}
}

const getMattressMessaging = (region: RegionShort) => {
	switch (region) {
		case 'us':
		case '':
			return 'We recommend using our 5-layer Mattress or any foam, non-spring mattress'
		case 'ca':
			return 'We recommend using our 3-layer Mattress or any foam, non-spring mattress'
		default:
			return 'We recommend using any foam, non-spring mattress'
	}
}
