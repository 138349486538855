import React, { FC, useEffect, useState } from 'react'
import styles from './ProductTitle.module.scss'
import { ProductTitleProps } from './types'
import { PricingInfo } from '../PricingInfo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { PriceManager } from 'prices'
import { FinancingInfo } from 'components/Financing/Financing'
import cx from 'classnames'
import { Visuals } from 'components/Phantom/_shop/Visuals'
import { PaymentOptionTabsConnected } from 'components/FinancingTabModal'
import { Type } from 'components/Type'
import useABTestVariant from 'hooks/useABTestVariant'
import { ugcAB } from 'ab_testing'
/**
 * Connected version of the `<ProductTitle/>` component. Automatically subscribes to the shop store.
 */

export const ProductTitleConnected = observer(() => {
	const { shopStore, priceStore } = useRootStore()
	const selectors = shopStore.currentSelectors
	const selectorsProps = selectors.map((selectorKey) => shopStore.getSelectorProps(selectorKey)).filter((x) => !!x)
	const currentVisuals = shopStore.getCurrentVisuals()
	const currentSelections = shopStore.getCurrentSelections()

	const props: ProductTitleProps = {
		title: shopStore.podData.name,
		price: shopStore.podVariant.prices.price,
		comparePrice: shopStore.podVariant.prices.comparePrice,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
		currency: priceStore.currency,
	}

	return (
		<>
			<div className={styles.mobile_visual}>
				<Visuals
					visuals={currentVisuals}
					currentSelectorKey={selectorsProps[0].selectorKey}
					renderSingle={true}
					currentSelections={currentSelections}
				/>
			</div>

			<div className={styles.sticky_wrapper_mobile}>
				<PaymentOptionTabsConnected />
			</div>

			{/* <BFCMShopToast /> */}

			<ProductTitle
				{...props}
				hidePricing={true}
				as={'h1'}
				modelSelected={currentSelections['pod-model']}
				// starsAllowed={true}
			/>
		</>
	)
})

/**
 * First shop page component. Displays the product title, price, and financing information.
 *
 * **Usually connected to the RootStore using the `<ProductTitleConnected/>` component**
 */
export const ProductTitle: FC<ProductTitleProps> = (props) => {
	const {
		title,
		price,
		comparePrice,
		currency,
		financingAmount,
		hidePricing = false,
		onlyDiscount = false,
		as = 'h1',
		modelSelected,
		typeComponent = 'h4',
		discountTagOverride,
		starsAllowed = false,
	} = props

	const [isHoverable, setIsHoverable] = useState(false)

	useEffect(() => {
		const tweetMarquee = document.getElementById('tweet-marquee')
		setIsHoverable(!!tweetMarquee)
	}, [])

	const TypeComponent = typeComponent === 'h2' ? Type.Headline2 : Type.Headline4
	const isUGCAB = useABTestVariant(ugcAB, 1)

	return (
		<section className={cx(styles.container, 'connected-title')}>
			<TypeComponent
				as={as}
				className={styles.product_title}
			>
				{title}
			</TypeComponent>
			{!hidePricing && (
				<>
					<PricingInfo
						price={PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}
						comparePrice={PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)}
						discountText={discountTagOverride || `${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
					/>
					<Type.SmallPrint className={styles.financing_info}>
						<FinancingInfo
							priceFormatted={financingAmount}
							className={styles.financing_info_inner}
						/>
					</Type.SmallPrint>
					<TruemedInfo className={styles.financing_info} />
				</>
			)}

			{isUGCAB && starsAllowed && (
				<div
					className={styles.stars_container}
					data-hoverable={isHoverable}
					onClick={() => {
						const tweetMarquee = document.getElementById('tweet-marquee')
						if (tweetMarquee) {
							tweetMarquee.scrollIntoView({ behavior: 'smooth', block: 'center' })
						}
					}}
				>
					<svg
						width="161"
						height="27"
						viewBox="0 0 161 27"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12.928 1.72662C13.3864 0.638002 14.9474 0.638003 15.4057 1.72662L18.1107 8.15182C18.3041 8.61075 18.7409 8.92432 19.2424 8.96404L26.2638 9.52015C27.4534 9.61437 27.9358 11.081 27.0294 11.8481L21.6799 16.3751C21.2978 16.6985 21.1309 17.2059 21.2476 17.6893L22.882 24.4582C23.1589 25.605 21.8961 26.5116 20.8775 25.897L14.8662 22.2696C14.4369 22.0105 13.8969 22.0105 13.4675 22.2696L7.45616 25.897C6.43766 26.5116 5.17478 25.605 5.45168 24.4582L7.08607 17.6893C7.20282 17.2059 7.03594 16.6985 6.65385 16.3751L1.30425 11.8481C0.397871 11.081 0.880253 9.61437 2.06989 9.52015L9.09132 8.96404C9.59285 8.92432 10.0297 8.61075 10.2229 8.15182L12.928 1.72662Z"
							fill="black"
						/>
						<path
							d="M46.2615 1.72662C46.7199 0.638002 48.2809 0.638003 48.7392 1.72662L51.4442 8.15182C51.6376 8.61075 52.0744 8.92432 52.5759 8.96404L59.5973 9.52015C60.7869 9.61437 61.2693 11.081 60.3629 11.8481L55.0134 16.3751C54.6313 16.6985 54.4644 17.2059 54.5811 17.6893L56.2155 24.4582C56.4924 25.605 55.2296 26.5116 54.211 25.897L48.1997 22.2696C47.7704 22.0105 47.2304 22.0105 46.801 22.2696L40.7897 25.897C39.7712 26.5116 38.5083 25.605 38.7852 24.4582L40.4196 17.6893C40.5363 17.2059 40.3694 16.6985 39.9873 16.3751L34.6377 11.8481C33.7314 11.081 34.2137 9.61437 35.4034 9.52015L42.4248 8.96404C42.9263 8.92432 43.3632 8.61075 43.5564 8.15182L46.2615 1.72662Z"
							fill="black"
						/>
						<path
							d="M79.5945 1.72662C80.0529 0.638002 81.6139 0.638003 82.0722 1.72662L84.7772 8.15182C84.9706 8.61075 85.4074 8.92432 85.9089 8.96404L92.9303 9.52015C94.1199 9.61437 94.6023 11.081 93.6959 11.8481L88.3464 16.3751C87.9643 16.6985 87.7974 17.2059 87.9141 17.6893L89.5485 24.4582C89.8254 25.605 88.5626 26.5116 87.544 25.897L81.5327 22.2696C81.1034 22.0105 80.5634 22.0105 80.134 22.2696L74.1227 25.897C73.1042 26.5116 71.8413 25.605 72.1182 24.4582L73.7526 17.6893C73.8693 17.2059 73.7024 16.6985 73.3204 16.3751L67.9708 11.8481C67.0644 11.081 67.5468 9.61437 68.7364 9.52015L75.7578 8.96404C76.2594 8.92432 76.6962 8.61075 76.8894 8.15182L79.5945 1.72662Z"
							fill="black"
						/>
						<path
							d="M112.928 1.72662C113.386 0.638002 114.947 0.638003 115.406 1.72662L118.111 8.15182C118.304 8.61075 118.741 8.92432 119.242 8.96404L126.264 9.52015C127.453 9.61437 127.936 11.081 127.029 11.848L121.68 16.3751C121.298 16.6985 121.131 17.2059 121.248 17.6893L122.882 24.4582C123.159 25.605 121.896 26.5116 120.878 25.897L114.866 22.2696C114.437 22.0105 113.897 22.0105 113.467 22.2696L107.456 25.897C106.438 26.5116 105.175 25.605 105.452 24.4582L107.086 17.6893C107.203 17.2059 107.036 16.6985 106.654 16.3751L101.304 11.848C100.398 11.081 100.88 9.61437 102.07 9.52015L109.091 8.96404C109.593 8.92432 110.03 8.61075 110.223 8.15182L112.928 1.72662Z"
							fill="black"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M160.691 10.2628L160.797 10.5961C160.885 10.8477 160.811 11.1273 160.611 11.3028L154.149 16.8361L156.064 25.1294C156.128 25.3945 156.029 25.6728 155.811 25.8361L155.518 26.0361C155.405 26.1254 155.264 26.1725 155.12 26.1694C154.998 26.172 154.878 26.1396 154.774 26.0761L147.501 21.6361L140.267 26.0761C140.163 26.1396 140.043 26.172 139.921 26.1694C139.777 26.1725 139.636 26.1254 139.523 26.0361L139.19 25.8361C138.972 25.6728 138.873 25.3945 138.937 25.1294L140.852 16.8361L134.403 11.3161C134.193 11.1417 134.113 10.8543 134.204 10.5961L134.35 10.2628C134.428 9.99931 134.662 9.81282 134.935 9.79609L143.432 9.11609L146.689 1.26275C146.794 1.00195 147.048 0.832396 147.328 0.836086H147.673C147.951 0.830176 148.202 1.00148 148.298 1.26275L151.609 9.11609L160.106 9.79609C160.379 9.81282 160.613 9.99931 160.691 10.2628ZM152.979 22.2494L151.49 16.0094L156.37 11.8361L149.974 11.3294L147.501 5.38275V18.9028L152.979 22.2494Z"
							fill="black"
						/>
					</svg>
					<Type.Body2>(4.6 stars) • 1,309 reviews</Type.Body2>
				</div>
			)}

			{onlyDiscount && (
				<PricingInfo
					price={''}
					comparePrice={''}
					discountText={discountTagOverride || `Up to ${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
				/>
			)}
		</section>
	)
}
