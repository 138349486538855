import { CTAProps } from 'components/_utils/utilityTypes'
import { TweetItem } from 'components/WallOfLove/wallOfLoveIterfaces'

export interface TweetMarqueeProps {
	header?: string
	cta?: CTAProps
	tweets?: Array<Omit<TweetItem, 'categories'>>
}

export const DEFAULT_TWEETS: TweetMarqueeProps['tweets'] = [
	{
		picture: 'https://pbs.twimg.com/profile_images/1519108511506046976/Hns7pWP__normal.jpg',
		name: 'Alex Iskold | 2048.vc',
		handle: 'alexiskold',
		unixDateMS: 1738240853000,
		platform: 'twitter',
		content:
			'There is no question that sleep is absolutely essential for feeling great daily and for your longevity.<br/><br/>Four things that enhance sleep for me:<br/><br/>1. @eightsleep - truly magical temperature regulating technology - I sleep so much at home with it<br/><br/>2. Stopping food 3-4 hours before… ',
		verified: false,
		url: 'https://twitter.com/alexiskold/status/1884944875918852593',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/71291561/Lynn_with_sunglasses_normal.jpg',
		name: 'Lynn Allendorf',
		handle: 'lynnallendorf',
		unixDateMS: 1735785641000,
		platform: 'twitter',
		content: "@overweightpet @eightsleep Thermostat doesn't help when one person is hot and the other cold. My husband and I LOVE our @eightsleep.",
		verified: false,
		url: 'https://twitter.com/lynnallendorf/status/1874646971178422414',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1881893620489359360/KKVDLMHs_normal.jpg',
		name: 'Rick Manelius',
		handle: 'rickmanelius',
		unixDateMS: 1738171115000,
		platform: 'twitter',
		content:
			"Wife was super skeptical of @eightsleep. <br/><br/>Then she realized she could crank of the heat in the middle of winter while reading her book.<br/><br/>She's sold.<br/><br/>Also. I was just sick for 4 days, and the ability to boost warmth those days helped a ton.",
		verified: false,
		url: 'https://twitter.com/rickmanelius/status/1884652373211017708',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644759365294129155/bVqR7Xc5_normal.jpg',
		name: 'Maggie Hott',
		handle: 'maggie_hott',
		unixDateMS: 1736028808000,
		platform: 'twitter',
		content:
			'Few things are more comforting when you’re battling a 102° fever and chills than cranking the heat and cozying up in an @eightsleep bed and drifting off to a nap. Thank you, @eightsleep and @m_franceschetti for making recovery just a little easier. 🙌 ',
		verified: false,
		url: 'https://twitter.com/maggie_hott/status/1875666884605223396',
		image: 'https://pbs.twimg.com/media/Gge1F0ta8AA4A1u.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1392722201787330561/oX7ZkknX_normal.jpg',
		name: 'Jeremy Cabral',
		handle: 'jeremycabral',
		unixDateMS: 1734993275000,
		platform: 'twitter',
		content: 'Clocked a perfect score on @eightsleep. Exactly what I needed ',
		verified: false,
		url: 'https://twitter.com/jeremycabral/status/1871323544518566399',
		image: 'https://pbs.twimg.com/media/GfhG1x6bAAAoG0i.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1648635733442437120/GUKJtle__normal.jpg',
		name: 'Phi',
		handle: 'dearphilippe',
		unixDateMS: 1737793712000,
		platform: 'twitter',
		content: 'Whoaaa I just entered a new dimension<br/><br/>Just finished setting up @eightsleep ultra, the best product I have tried since the AirPods. ',
		verified: false,
		url: 'https://twitter.com/dearphilippe/status/1883069428713279807',
		image: 'https://pbs.twimg.com/media/GiIBqXVbYAATnF-.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1855848712641380352/ECfMlUtb_normal.jpg',
		name: 'RIZZO🤘🏻',
		handle: 'Gigglyspector',
		unixDateMS: 1724669647000,
		platform: 'twitter',
		content: 'Still not of gotten over my bed moving the head up to stop my husbands snoring…Thank you @eightsleep you’re a real one🙌🏻<br/>#insomniasucks',
		verified: false,
		url: 'https://twitter.com/Gigglyspector/status/1828023110249107630',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1869117014129709056/f_9liU2x_normal.jpg',
		name: 'Azi',
		handle: 'azi_d20',
		unixDateMS: 1736858063000,
		platform: 'twitter',
		content: '@ItzSuds Get an @eightsleep <br/><br/>Read/listen to everything @hubermanlab and @bryan_johnson have to say about sleep',
		verified: false,
		url: 'https://twitter.com/azi_d20/status/1879145033695469877',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495067453096747008/Wew9lSno_normal.jpg',
		name: 'Steven McCall',
		handle: 'S_McCall7',
		unixDateMS: 1736396196000,
		platform: 'twitter',
		content: '@jlinehan9 Buy an @eightsleep will literally change your life. And you don’t have to wear anything extra. Goes on your mattress, tracks metrics and helps you get deeper sleep',
		verified: false,
		url: 'https://twitter.com/S_McCall7/status/1877207823899078749',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1859065838923608064/UQWckdBi_normal.jpg',
		name: 'Krista Doebel-Hickok',
		handle: 'KristabelDH',
		unixDateMS: 1737430070000,
		platform: 'twitter',
		content: 'Normally, I like my @eightsleep at +2. But, after today’s 20 min power test, my body is telling me it needs a cool bed. So, -1, and sweet dreams 😴 ',
		verified: false,
		url: 'https://twitter.com/KristabelDH/status/1881544203538399492',
		image: 'https://pbs.twimg.com/media/GhyWetGWoAA5dBs.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1392722201787330561/oX7ZkknX_normal.jpg',
		name: 'Jeremy Cabral',
		handle: 'jeremycabral',
		unixDateMS: 1736202530000,
		platform: 'twitter',
		content:
			'When lying flat, pressure is concentrated in your lower back and hips. I’ve been running a lot lately and I’ve had pain in both. I had no idea that during your sleep the Pod 4 Ultra base helps mitigate this by evenly by raising your head and feet, taking pressure off your back. ',
		verified: false,
		url: 'https://twitter.com/jeremycabral/status/1876395530634535223',
		image: 'https://pbs.twimg.com/media/GgpLyUjaQAA9HQU.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1349908633920827393/-xjB1YKc_normal.jpg',
		name: 'litch_e',
		handle: 'Litch_E',
		unixDateMS: 1734541158000,
		platform: 'twitter',
		content: "@eightsleep It's true. It's the best investment my wife and I have made for our sleep health. She's warm, I'm cool. Easier to fall asleep and stay asleep",
		verified: false,
		url: 'https://twitter.com/Litch_E/status/1869427229358469458',
	},
]
