/* eslint-disable @next/next/no-img-element */
import React, { cloneElement, FC, ReactElement, useRef, useState } from 'react'
import styles from './PodFamilySizeExplanationModal.module.scss'
import cx from 'classnames'
import { amClickedButton, amCloseMembershipModal, amOpenMembershipModal } from 'events/amplitude'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { regionIsEU, RegionShort } from 'utils/internationalization'
import { IconMap } from 'components/_const_assets/icons'
import { ModalTriggerNew } from 'components/CoverModelExplanationModal'
import { SizeGuideBed, SizeGuideBedProps } from 'components/SizeGuideBed/SizeGuideBed'

interface ModalProps {
	rootElementId: string
	triggerElement: ReactElement
	onOpen?: () => void
	onClose?: () => void
	title: string
	region: RegionShort
	id: number
}

export interface PodFamilySizeExplanationModalProps {
	title: string
	region: RegionShort
	id: number
}

const SizesUS = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Full'}
				length={75}
				width={53}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Queen'}
				length={80}
				width={60}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'King'}
				length={80}
				width={76}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Cali King'}
				length={84}
				width={72}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesCA = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'CA Full'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'CA Queen'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'CA King'}
				length={200}
				width={190}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesEU = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Large Double'}
				length={200}
				width={160}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Super King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesAE = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Emperor'}
				length={200}
				width={200}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesAU = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Queen'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesUK = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'UK Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'UK King'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'UK Super King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesMX = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'MX Matrimonial'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'MX Queen'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'MX King'}
				length={200}
				width={190}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

export const PodFamilySizeExplanationModal: FC<PodFamilySizeExplanationModalProps> = (props) => {
	const id = 'open-pod-size-explanation-modal'
	return (
		<Modal
			rootElementId="__next"
			triggerElement={
				<ModalTriggerNew
					topText={'Need help choosing a size?'}
					bottomText={'Explore the differences in size'}
					id={id}
					onClick={() => amClickedButton('Compare sizes link', id)}
				/>
			}
			onOpen={amOpenMembershipModal}
			onClose={amCloseMembershipModal}
			title={props.title}
			region={props.region}
			id={props.id}
		/>
	)
}

export const AccessoriesSizeExplanationModal: FC<PodFamilySizeExplanationModalProps> = (props) => {
	const modalTrigger = (
		<button
			className={styles.trigger}
			id={'open-pod-size-explanation-modal'}
		>
			Need a help choosing a size?
		</button>
	)

	return (
		<div className={styles.tonal_modal}>
			<div className={styles.ccc}>
				<Modal
					rootElementId="__next"
					triggerElement={modalTrigger}
					onOpen={amOpenMembershipModal}
					onClose={amCloseMembershipModal}
					title={props.title}
					region={props.region}
					id={props.id}
				/>
			</div>
		</div>
	)
}
const Modal: FC<ModalProps> = (props) => {
	const [modalVisible, setModalVisible] = useState(false)
	const pageWrapper = useRef(null)

	function showModal() {
		setModalVisible(true)
		if (props.onOpen) {
			props.onOpen()
		}
	}
	function hideModal() {
		setModalVisible(false)
		props.onClose?.()
	}

	useClickOutsideModal(pageWrapper, hideModal, false)

	const SizeTable = () => {
		if (props.region === 'au') {
			return SizesAU()
		}
		if (props.region === 'uk') {
			return SizesUK()
		}
		if (regionIsEU(props.region) || props.region === 'ch') {
			return SizesEU()
		}
		if (props.region === 'ca') {
			return SizesCA()
		}
		if (props.region === 'mx') {
			return SizesMX()
		}
		if (props.region === 'ae') {
			return SizesAE()
		}
		return SizesUS()
	}

	return (
		<div className={cx('modal')}>
			{cloneElement(props.triggerElement, { onClick: showModal })}
			{modalVisible && (
				<>
					<FocusTrapWrapper active={true}>
						<div className={'modal_container'}>
							<div
								className={styles.modal_content_container}
								ref={pageWrapper}
							>
								<button
									role="button"
									aria-label="Close modal button"
									className={styles.close_button}
									onClick={hideModal}
								>
									<img
										src={IconMap.close}
										alt="close icon"
									/>
								</button>
								<div className={cx(styles.content)}>
									<h2 className={styles.size_guide_header}>{props.title}</h2>
									<div className={styles.size_table_container}>
										<SizeTable />
									</div>
								</div>
							</div>
						</div>
					</FocusTrapWrapper>
				</>
			)}
		</div>
	)
}

interface BedSizeEntryProps extends SizeGuideBedProps {
	title: string
}

const BedSizeEntry: FC<BedSizeEntryProps> = (props) => (
	<>
		<p>{props.title}</p>
		<SizeGuideBed {...props} />
	</>
)
